import React, { useState, useContext, useEffect } from 'react'

const SessionContext = React.createContext();
const SessionUpdateContext = React.createContext();

export function useSession() {
    return useContext(SessionContext);
}

export function useSessionUpdate() {
    return useContext(SessionUpdateContext);
}

// Common variables and functions for all components
export function SessionContextProvider({ children }) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [hasLoginCheckRun, setHasLoginCheckRun] = useState(false);
    const [username, setUsername] = useState('');
    const [language, setLanguage] = useState('tag_en');
    const [accountType, setAccountType] = useState('student');

    useEffect(() => {
        checkLoginStatus().catch(error =>
            console.error("Fetch operation failed.", error));
    }, []);

    async function checkLoginStatus() {
        const response = await fetch('/api/checkLoginStatus');
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();

        if (data.status && !isLoggedIn) {
            setIsLoggedIn(data.status);
            setUsername(data.username);
            setLanguage(data.language);
            setAccountType(data.account_type);
        } else if (!data.status && isLoggedIn) {
            setIsLoggedIn(data.status);
        }

        setHasLoginCheckRun(true);
    }

    async function handleLogout() { // Logout user so stop working with the user data
        const response = await fetch('/api/logout');
        if (!response.ok) throw new Error('Network response was not ok');
        setUsername('');
        setIsLoggedIn(false);
    }

    function insertStringInPhrase(phrase, toInsert) { // string, list of strings to insert (make sure they are in the right order)
        let amountToInsert = phrase.replace(/[^<]/g, '').length;
        let finalPhrase = "";

        let openInsert = phrase.indexOf('<');
        let closeInsert = phrase.indexOf('>') + 1;
        let start = 0;

        for (let i = 0; i < amountToInsert; i++) {
            let nextInsert = phrase.indexOf('<', openInsert + 1);
            // console.log(nextInsert);

            finalPhrase += phrase.slice(start, openInsert) + toInsert[i];
            finalPhrase += nextInsert === -1 ? phrase.slice(closeInsert) : phrase.slice(closeInsert, nextInsert);
            // console.log(finalPhrase)

            start = openInsert = nextInsert;
            closeInsert = phrase.indexOf('>', closeInsert) + 1;
        }

        return finalPhrase;
    }

    return (
        <SessionContext.Provider
            value={{
                language, accountType, isLoggedIn, username, hasLoginCheckRun,
                setLanguage, setAccountType, setIsLoggedIn, setUsername
            }}
        >
            <SessionUpdateContext.Provider
                value={{
                    handleLogout, insertStringInPhrase
                }}
            >
                {hasLoginCheckRun && // assists PrivateRoute.jsx
                    <>{children}</>
                }
            </SessionUpdateContext.Provider>
        </SessionContext.Provider>
    )
}