import React, { useState, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowCircleLeft, faUsers, faCog, faBook, faCheck, faSignOutAlt, faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons"
import DeckSelection from "./DeckSelection"
import ClassroomGird from "./ClassroomGrid"
// import FlashcardGrid from './FlashcardGrid';
import FlashcardForms from './util/FlashcardForms'
import Results from './Results'
import ConfirmDelete from "./util/ConfirmDelete"
import "./styles/Classroom.scss"
import titles from '../data/titles.json';
import { useSession } from './SessionContext';

const Classroom = (props) => {

    const { language, accountType } = useSession();

    const [chosenClassroom, setChosenClassroom] = useState(props.location.chosenClassroom ? props.location.chosenClassroom : {})
    const [chosenUserID, setChosenUserID] = useState(-1) // -1 defaults to you
    const [memberData, setMemberData] = useState([])
    const [tab, setTab] = useState("collections");
    const [renameClassroom, setRenameClassroom] = useState("");

    // Used in members chart
    const [classCollections, setClassCollections] = useState([]);
    const [classDecks, setClassDecks] = useState([]);

    const [chosenCollectionOption, setChosenCollectionOption] = useState(0);
    const [chosenDeckID, setChosenDeckID] = useState(-1);
    const [chosenQuizType, setChosenQuizType] = useState("conjugate");

    const [showConfirmDelete, setShowConfirmDelete] = useState(false);

    const [maxAttempts, setMaxAttempts] = useState(0);

    const [attemptToView, setAttemptToView] = useState({});
    const [overallStats, setOverallStats] = useState({ averageScore: 0, lowestScore: 0, highestScore: 0 });

    const [statFilter, setStatFilter] = useState(-1);

    const [flashcards, setFlashcards] = useState([]);


    useEffect(() => { // Update the view for member stats
        if (tab !== "collections") getClassroomMembers()
    }, [chosenDeckID, chosenQuizType])

    useEffect(() => {
        if (tab !== "collections") findFirstDeckID(classCollections, classDecks)
    }, [chosenCollectionOption])

    useEffect(() => { // Once data is recieved, generate stats
        if (memberData.length > 0) generateOverallStats()
    }, [statFilter])

    function recieveCollections(collections, decks) { // Recieve class collections/decks from child
        setClassCollections(collections);
        setClassDecks(decks);
        findFirstDeckID(collections, decks);
    }

    function findFirstDeckID(collections, decks) { // Set the deck id to the first deck in the collection
        for (let i = 0; i < decks.length; i++) {
            if (decks[i].collection_id === collections[chosenCollectionOption].id) {
                setChosenDeckID(decks[i].id);
                return;
            }
        }
    }

    async function getClassroomMembers() { // rename??
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ class_id: chosenClassroom.id, deck_id: chosenDeckID, quiz_type: chosenQuizType }),
        }

        const response = await fetch("/api/getClassroomMembers", requestOptions)
        if (!response.ok) return
        const data = await response.json()

        console.log(data)

        let attemptsLengths = []

        if (data.members[0].attempts !== undefined) { // Get every players number of attempts
            for (let m = 0; m < data.members.length; m++) attemptsLengths.push(data.members[m].attempts.length);
        }

        let newFlashcards = []; // Clone flashcards for easy manipulation

        // Store attributes of every verb
        for (let i = 0; i < data.flashcards.length; i++) {
            newFlashcards.push({
                tense: data.flashcards[i].tense_tag,
                mood: data.flashcards[i].mood_tag,
                person: data.flashcards[i].person_tag,
                number: data.flashcards[i].number_tag,
                infinitive: data.flashcards[i].root_tag,
                verb: data.flashcards[i].verb,
                id: data.flashcards[i].id,
                correct: data.flashcards[i].correct
            });
        }
        // console.log(data.members.length)

        setMaxAttempts(Math.max(...attemptsLengths))
        setMemberData(data.members)
        setFlashcards(newFlashcards);
        if (data.members.length > 0) generateOverallStats([...data.members])
    }

    function generateOverallStats(initialMemberData = []) {
        let attemptsToFilter = []
        let memberDataClone = initialMemberData.length > 0 ? [...initialMemberData] : [...memberData];

        for (let m = 0; m < memberDataClone.length; m++) {
            if (memberDataClone[m].attempts !== undefined) {
                if (statFilter < 0) {
                    for (let a = 0; a < memberDataClone[m].attempts.length; a++) {
                        attemptsToFilter.push(memberDataClone[m].attempts[a])
                    }
                } else if (memberDataClone[m].attempts[statFilter] !== undefined) {
                    attemptsToFilter.push(memberDataClone[m].attempts[statFilter])
                }
            }
        }

        if (attemptsToFilter.length === 0) {
            setOverallStats({ classAverage: 0, lowestScore: 0, highestScore: 0 });
            // setOverallStats({ classAverage: 0, lowestScore: { score: 0, deck_size: 1 }, highestScore: { score: 0, deck_size: 1 } });
            return;
        }

        let classAverage = attemptsToFilter.reduce(function (sum, value) {
            return sum + (value.score / value.deck_size);
        }, 0) / attemptsToFilter.length * 100;

        let lowestScore = attemptsToFilter.reduce(function (prev, curr) { return prev.score / prev.deck_size < curr.score / curr.deck_size ? prev : curr; });
        let highestScore = attemptsToFilter.reduce(function (prev, curr) { return prev.score / prev.deck_size > curr.score / curr.deck_size ? prev : curr; });

        setOverallStats({
            classAverage: classAverage.toFixed(2),
            lowestScore: (lowestScore.score / lowestScore.deck_size * 100).toFixed(2),
            highestScore: (highestScore.score / highestScore.deck_size * 100).toFixed(2)
        });
    }

    async function manipulateClassroom(func) {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ class_id: chosenClassroom.id, name: renameClassroom }),
        }

        const response = await fetch(func, requestOptions)
        if (!response.ok) return
        const data = await response.json()

        if (func === '/api/deleteClassroom' || func === '/api/leaveClassroom') {
            setChosenClassroom({});
            func === '/api/deleteClassroom' ?
                alert(`${chosenClassroom.name} deleted!`) :
                alert(`Left ${chosenClassroom.name}`);
            return;
        }
        else if (func === '/api/renameClassroom') {
            let chosenClassroomClone = chosenClassroom;
            chosenClassroomClone.name = renameClassroom;
            setChosenClassroom({ ...chosenClassroomClone });
        }
    }

    return (
        <>
            <div className="ClassroomGrid">
                <div className="head">
                    <div className="title">
                        {chosenClassroom.name !== undefined && (
                            <FontAwesomeIcon
                                icon={faArrowCircleLeft}
                                title="Back"
                                onClick={() => {
                                    setChosenClassroom({});
                                    setTab("collections");
                                    sessionStorage.setItem('classroom', "{}");
                                }}
                            />
                        )}
                        <>
                            {chosenClassroom.name !== undefined ? (
                                <>
                                    <h2>{chosenClassroom.name}</h2>
                                    <span className="header-class-code">{titles[language].class_code}: {chosenClassroom.class_code}</span>
                                </>
                            ) : (
                                <h2>{titles[language].classrooms}</h2>
                            )}
                        </>
                    </div>
                    {chosenClassroom.name !== undefined && (
                        <div className="classroom-details">
                            <FontAwesomeIcon
                                icon={faBook}
                                style={{ color: tab === "collections" && 'hsl(255, 90%, 92%)' }}
                                title="Classroom Collections"
                                onClick={() => setTab("collections")}
                            />
                            {accountType === "teacher" &&
                                <FontAwesomeIcon
                                    icon={faUsers}
                                    style={{ color: tab === "members" && 'hsl(255, 90%, 92%)' }}
                                    title="Members"
                                    onClick={() => { getClassroomMembers(); setTab("members"); }}
                                />
                            }
                            <FontAwesomeIcon
                                icon={faCog}
                                style={{ color: tab === "settings" && 'hsl(255, 90%, 92%)' }}
                                title="Settings"
                                onClick={() => { setRenameClassroom(""); setTab("settings"); }}
                            />
                        </div>
                    )}
                </div>
                {chosenClassroom.id === undefined || JSON.parse(sessionStorage.classroom).id === undefined || props.parent === "DeckSelection" ? (
                    <ClassroomGird
                        chosenClassroom={chosenClassroom}
                        setChosenClassroom={setChosenClassroom}
                        parent={props.parent}
                        collectionForClass={props.collectionForClass}
                    />
                ) : <>
                    {tab !== "collections" &&
                        <div className="classroom-window">
                            {tab === "members" &&
                                <>
                                    {classCollections.length > 0 &&
                                        <div className="select-evaluation">
                                            <select
                                                value={chosenCollectionOption}
                                                onChange={e => { setStatFilter(-1); setChosenCollectionOption(e.target.value); }}
                                            >
                                                {classCollections.map((collection, c) =>
                                                    <option key={c} value={c}> {collection.name} </option>
                                                )}
                                            </select>
                                            <select
                                                value={chosenDeckID}
                                                onChange={e => { setStatFilter(-1); setChosenDeckID(e.target.value); }}
                                            >
                                                {classDecks.map((deck, d) => {
                                                    if (deck.collection_id === classCollections[chosenCollectionOption].id) {
                                                        return <option key={d} value={deck.id}> {deck.name} </option>
                                                    }
                                                })}
                                            </select>
                                            <select
                                                value={chosenQuizType}
                                                onChange={e => { setStatFilter(-1); setChosenQuizType(e.target.value); }}
                                            >
                                                <option value={"conjugate"}>{titles[language].conjugate}</option>
                                                <option value={"identify_tense"}>{titles[language].identify_tense}</option>
                                                <option value={"mixture"}>{titles[language].mixture}</option>
                                            </select>
                                        </div>
                                    }
                                    <div className="table-wrapper">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>{titles[language].members}</th>
                                                    {maxAttempts > 0 ?
                                                        <>{[...Array(maxAttempts)].map((attempt, a) => <th key={a}>{titles[language].attempt} {a + 1}</th>)}</>
                                                        :
                                                        <th>{titles[language].attempt} 1</th>
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {memberData.map((member, m) =>
                                                    <tr key={m}>
                                                        <td className="username">
                                                            <div>{member.username}</div>
                                                        </td>
                                                        {maxAttempts > 0 ?
                                                            <>
                                                                {[...Array(maxAttempts)].map((ma, a) => {
                                                                    if (a < member.attempts.length) {
                                                                        let attempt = member.attempts[a]
                                                                        return <td key={a}
                                                                            className="stat"
                                                                            onClick={() => setAttemptToView({ ...attempt, attemptNumber: a + 1, username: member.username })}
                                                                        >
                                                                            <div className="score">{attempt.score}/{attempt.deck_size} ~ {attempt.points}pts</div>
                                                                            <div className="date">{attempt.created}</div>
                                                                        </td>
                                                                    }
                                                                    return <td key={a} className="stat">-</td>
                                                                })}
                                                            </>
                                                            :
                                                            <td>-</td>
                                                        }
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    {classCollections.length > 0 && maxAttempts > 0 &&
                                        <div className="overall-stats">
                                            <select
                                                value={statFilter}
                                                onChange={e => setStatFilter(e.target.value)}
                                            >
                                                <option key={-1} value={-1}>{titles[language].all_attempts}</option>
                                                {[...Array(maxAttempts)].map((ma, a) => <option key={a + 1} value={a}> {titles[language].attempt} {a + 1} </option>)}
                                            </select>
                                            <table><thead><tr>
                                                <th>{titles[language].average}: {overallStats.classAverage}%</th>
                                                <th>{titles[language].lowest}: {overallStats.lowestScore}%</th>
                                                <th>{titles[language].highest}: {overallStats.highestScore}%</th>
                                            </tr></thead></table>
                                        </div>
                                    }
                                </>
                            }
                            {tab === "settings" &&
                                <div>
                                    <h2 className="header">Settings</h2>
                                    {accountType === "teacher" ?
                                        <div className="settings">
                                            <div className="rename">
                                                <input
                                                    placeholder={titles[language].rename_classroom}
                                                    value={renameClassroom}
                                                    onChange={e => setRenameClassroom(e.target.value)}
                                                    onKeyPress={e => (e.keyCode || e.which === 13) && manipulateClassroom('/api/renameClassroom')}
                                                />
                                                <button
                                                    className="green-button"
                                                    onClick={() => manipulateClassroom('/api/renameClassroom')}
                                                >
                                                    Confirm
                                                </button>
                                            </div>
                                            <div>
                                                <button className="red-button" onClick={() => setShowConfirmDelete(true)}>
                                                    {titles[language].delete_classroom}
                                                </button>
                                            </div>
                                        </div>
                                        :
                                        <div className="settings">
                                            <button
                                                onClick={() => manipulateClassroom('/api/leaveClassroom')}
                                                title="Leave Classroom"
                                                disabled={true}
                                            >
                                                {titles[language].unenroll} <FontAwesomeIcon icon={faSignOutAlt} />
                                            </button>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    }
                </>
                }
            </div>
            {chosenClassroom.id !== undefined && props.parent !== "DeckSelection" && tab === "collections" &&
                <DeckSelection
                    chosenClassroom={chosenClassroom}
                    chosenClassroomID={chosenClassroom.id}
                    chosenUserID={chosenClassroom.teacher_id}
                    recieveCollections={recieveCollections}
                    parent="Classroom"
                />
            }

            {(flashcards.length > 0 && tab === "members") &&
                <>
                    <h3 className="title-inc">{titles[language].least_correct} <FontAwesomeIcon icon={faLongArrowAltRight} /> {titles[language].most_correct}</h3>
                    {/* <FlashcardGrid
                        flashcards={flashcards}
                        parent={"Members"}
                        chosenUserID={chosenUserID}
                    /> */}
                    <div className="incorrect-correct">
                        {flashcards.map((flashcard, f) => {
                            return <div className="FlashcardForms" key={f}>
                                <span>{flashcard.correct} {titles[language].correct_attempts}</span>
                                <div className={chosenQuizType}>
                                    <FlashcardForms
                                        verb={flashcard.verb}
                                        infinitive={flashcard.infinitive}
                                        tags={{
                                            tense: flashcard.tense,
                                            mood: flashcard.mood,
                                            person: flashcard.person,
                                            number: flashcard.number
                                        }}
                                        flashcardMode={chosenQuizType}
                                        choice={""}
                                    // parent="Results"
                                    />
                                </div>
                            </div>
                        })}
                    </div>
                </>
            }
            {showConfirmDelete &&
                <ConfirmDelete
                    parent={"Classroom"}
                    objectToDelete={chosenClassroom}
                    setShowConfirmDelete={setShowConfirmDelete}
                    manipulateClassroom={manipulateClassroom}
                />
            }
            {attemptToView.deck_id &&
                <div className="DSoverlay">
                    <Results
                        quizType={attemptToView.quiz_type}
                        points={attemptToView.points}
                        deck_size={attemptToView.deck_size}
                        class_history_id={attemptToView.id}
                        correct={attemptToView.score}
                        setAttemptToView={setAttemptToView}
                        previousAttempt={true}
                        attemptNumber={attemptToView.attemptNumber}
                        deckName={classDecks.find(d => d.id === chosenDeckID).name}
                        username={attemptToView.username}
                    />
                </div>
            }
        </>
    )
}

export default Classroom
