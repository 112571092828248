import linguisticInfo from '../../data/linguisticInfo.json';

// Returns the form of a verb
const Label = (props) => {
    let verbForm = "";
    const attributes = ["tense", "mood", "person", "number"]; // Specifies the order to loop through the attributes

    // console.log(props.tags, attributes);

    // Loop through linguistic attributes - tense, mood, person, number
    for (const attribute of attributes) {
        // Loop through the forms they offer
        for (const form of linguisticInfo[attribute]) {
            // console.log(props.tags[attribute], attribute, form.tag_name, form)
            if (props.tags[attribute] === form.tag_name) {
                verbForm += form["tag_it"] + " ";
                break;
            }
        }
    }
    return verbForm;
}

export default Label;