import React from 'react';
import './styles/App.scss';


class Account extends React.Component {
    constructor() {
        super()
        this.state = {
            
        }
    }

    render() {
        return (
            <div>
                
            </div>
        )
    }
}

export default Account;
