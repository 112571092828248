import React from 'react';
import { Link } from 'react-router-dom';
import './styles/ChooseQuiz.scss';
import titles from '../data/titles.json';
import { useSession } from './SessionContext';

const ChooseQuiz = (props) => {
    const { language } = useSession();

    function filterIncompatibleFlashcards() {
        let compatibleFlashcards = [...props.flashcards];
        let remove = false;

        // Remove cards that are not compatible with the chosen quiz type
        for (let i = 0; i < compatibleFlashcards.length; i++) {
            let flashcard = compatibleFlashcards[i];
            remove = false;

            if (flashcard.tense === "") remove = true;

            if (remove) {
                compatibleFlashcards.splice(i, 1);
                i--;
            }
        }

        return compatibleFlashcards;
    }

    function mixFlashcards(quizType) {
        let flashcardsToMix = filterIncompatibleFlashcards(quizType);

        for (let j = flashcardsToMix.length - 1; j > 0; j--) {
            const k = Math.floor(Math.random() * (j + 1));
            [flashcardsToMix[j], flashcardsToMix[k]] = [flashcardsToMix[k], flashcardsToMix[j]];
        }

        return flashcardsToMix;
    }

    let quizAttributes = {
        pathname: `/quiz/${props.chosenDeck.name}`,
        chosenClassroom: props.chosenClassroom,
        chosenDeck: props.chosenDeck,
        parent: props.parent,
        class_id: props.class_id,
        language: language // passed to quiz as a prop.location ideally should change to grabbing from session context
    }

    return (
        <div
            className="ChooseQuiz deckDetail"
            style={props.chosenDeck.id > -1 ? {} : { display: 'none' }}
        >
            <div className="deckTitle">
                <h2>{props.chosenDeck.name}</h2>
                <h4>{props.flashcards.length} cards</h4>
            </div>
            <div className="quizzes">
                <h5>{titles[language].quizzes}</h5>
                <section className="quiz-links">
                    <Link to={{ ...quizAttributes, quizType: "conjugate", flashcards: mixFlashcards("conjugate") }}>
                        <button>{titles[language].conjugate}</button>
                    </Link>
                    {/* <Link to={{ ...quizAttributes, quizType: "identify_tense", flashcards: mixFlashcards("identify_tense") }}> */}
                    <button disabled>{titles[language].identify_tense}</button>
                    {/* </Link> */}
                    {/* <Link to={{ ...quizAttributes, quizType: "mixture", flashcards: mixFlashcards("conjugate") }}> */}
                    <button disabled>{titles[language].mixture}</button>
                    {/* </Link> */}
                </section>
                {props.flashcards.length === 0 &&
                    <div className="instruction">
                        {titles[language].empty_deck_message}
                    </div>
                }
            </div>
        </div>
    );
}

export default ChooseQuiz;