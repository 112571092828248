import React, { useState, useEffect } from 'react';
import Label from './Label';
import GeneratePronoun from './GeneratePronoun';
import '../styles/FlashcardForms.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltDown } from '@fortawesome/free-solid-svg-icons';

const FlashcardForms = (props) => {
    const [verbInput, setVerbInput] = useState("");
    const [inputMode, setInputMode] = useState("verbInput");

    // Makes input the choice
    useEffect(() => {
        setVerbInput(props.choice);
        setInputMode("hintInput");
    }, [props.choice]);

    // When Check button is pressed on Quiz component
    useEffect(() => {
        props.answered === true && isCorrect();
    }, [props.answered]);

    // Reset input for next flashcard
    useEffect(() => {
        setVerbInput("");
        setInputMode("verbInput")
    }, [props.questionNumber]);

    // Changes the value of the verb input if the user didn't submit their answer
    function handleChange(event) {
        if (props.parent === "Results") {
            setVerbInput(event);
        }
        else if (props.parent === "Quiz") {
            props.answered === false && setVerbInput(event.target.value);
        } else {
            setVerbInput(event.target.value);
        }
    }

    async function isCorrect() {
        // Check if answer is correct for type mode /**FIX how colors change */
        if ((props.flashcardMode === "conjugate") &&
            ((props.parent === "FlashcardGrid") || (inputMode === "verbInput") || (inputMode === "hintInput"))) {

            // If answer is correct
            if (verbInput === props.verb) {
                setInputMode("rightInput");
                if (props.parent === "Quiz") {
                    let res = await props.processResult(true, verbInput);
                    console.log(res)
                }
            }
            else {
                setInputMode("wrongInput");
                if (props.parent === "Quiz") {
                    let res = await props.processResult(false, verbInput);
                    // console.log(res)
                }
            }
        }

        // Check if answer is correct for tense mode /**change where these are maybe keep the input stuff here only */
        if ((props.flashcardMode === "identify_tense") && (props.parent === "Quiz")) {
            if (props.choice === props.tags.tense) {
                let res = await props.processResult(true, props.choice);
                console.log(res)
            }
            else {
                let res = await props.processResult(false, props.choice);
                console.log(res)
            }
        }
    }

    let attributesSide = <figure className={(props.flashcardMode === "side1" || props.flashcardMode === "both_sides") ? "front" : "back2"}>
        <div className='upper'>
            <Label
                tags={props.tags}
            />
        </div>
        <div className='delim'>of</div>
        <div className='lower'>
            {props.infinitive}
        </div>
    </figure>;

    let conjugatedSide = <figure className={props.flashcardMode === "side1" ? "back" : "front2"}>
        {props.verb}
    </figure>;

    if (props.flashcardMode === "raw") {
        return <>
            <div className='upper'>
                <Label
                    tags={props.tags}
                />
            </div>
            <div className='delim'><hr /></div>
            <div className='lower'>
                {props.verb}
            </div>
        </>
    }
    else if (props.flashcardMode === "side1") {
        return <div className="card">
            {attributesSide}{conjugatedSide}
        </div>
    }
    else if (props.flashcardMode === "side2") {
        return <div className="card">
            {conjugatedSide}{attributesSide}
        </div>
    }
    else if (props.flashcardMode === "both_sides") {
        return <>
            <div className="card">{attributesSide}</div>
            <div className="card">{conjugatedSide}</div>
        </>
    }
    // Question Type 1 - Fill in the verb
    else if (props.flashcardMode === "conjugate") { // Move wrapper here
        return <>
            <div className="form">
                <b>
                    <Label
                        tags={{ tense: props.tags.tense, mood: props.tags.mood }} // Just show the tense and mood
                    />
                </b>
            </div>
            <div className="infinitive">
                {props.infinitive}
            </div>
            <div className="blank">
                {(inputMode === "wrongInput" || (props.parent === "Results" && props.history.correct === 0)) &&
                    <div className="showAnswer">{props.verb}</div>
                }
                <span className="pronoun">
                    <GeneratePronoun
                        person={props.tags.person}
                        number={props.tags.number}
                    />
                </span>
                {props.parent === "Results" ?
                    <span className={props.history.correct === 1 ? "rightInput" : "wrongInput"}>
                        {props.history.correct === 1 ?
                            <>{props.verb}</> :
                            <span className={props.history?.attempt}>
                                {props.history?.attempt}
                            </span>
                        }
                    </span>
                    :
                    <input
                        placeholder="verb"
                        type="text"
                        className={inputMode}
                        value={verbInput}
                        onChange={handleChange}
                        onKeyPress={event => {
                            if (event.keyCode || event.which === 13) {
                                if (props.parent === "Quiz") {
                                    if (props.answered && props.questionNumber < props.questionAmount - 1) {
                                        props.nextQuestion();
                                        return;
                                    }
                                    props.setAnswered(true);
                                    return;
                                }
                                isCorrect(); // For Home
                            }
                        }}
                        onPaste={(event) => {
                            event.preventDefault();
                            return false;
                        }}
                        onCopy={(event) => {
                            event.preventDefault();
                            return false;
                        }}
                    />
                }
            </div>
        </>
    } // Question Type 2 - What's the tense
    else if (props.flashcardMode === "identify_tense") {
        return <>
            <div>
                {props.infinitive}
            </div>
            <div>
                <FontAwesomeIcon icon={faLongArrowAltDown} className="down" />
                {props.parent === "Results" &&
                    <span>
                        <div className='rightInput'>
                            <Label
                                tags={{ tense: props.tags.tense }}
                            />
                        </div>
                        {props.history.correct === 0 &&
                            <div className='wrongInput'>
                                <Label
                                    tags={{ tense: props.history.attempt }} // Just show the tense and mood
                                />
                            </div>
                        }
                    </span>
                }
            </div>
            <div>
                <span className="pronoun">
                    <GeneratePronoun
                        person={props.tags.person}
                        number={props.tags.number}
                    />
                </span>
                &nbsp;{props.verb/**check about formatting this nice */}
            </div>
        </>
    }
    return null;
}

export default FlashcardForms;        