import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle, faTimesCircle, faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import './styles/Classroom.scss';
import titles from '../data/titles.json';
import { useSession, useSessionUpdate } from './SessionContext';

const ClassroomGrid = (props) => {
    const [classes, setClasses] = useState([]);
    const [chosenClassrooms, setChosenClassrooms] = useState([]); // For adding a collection to classrooms
    const [openInput, setOpenInput] = useState(false);
    const [classNameInput, setClassNameInput] = useState("");

    const { accountType, language } = useSession();
    const { insertStringInPhrase } = useSessionUpdate();

    useEffect(() => {
        getClasses();
    }, []);

    useEffect(() => {

    }, [classes]);

    function handleChange(event) {
        setClassNameInput(event.target.value);
    }

    //copy rename (1)
    // tags
    // teacher refreence
    // filters

    async function getClasses() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ collection: props.collectionForClass !== undefined ? props.collectionForClass : { id: -1 } })
        };
        const response = await fetch('/api/getClasses', requestOptions);
        if (!response.ok) return;
        const data = await response.json();

        setChosenClassrooms(Array(data.classes.length).fill({ id: -1 }));
        setClasses(data.classes);

        console.log(data.classes)
    }

    // Join or create a new class
    async function newClassroom() {
        const newClassFunc = accountType === 'student' ? '/api/joinClass' : '/api/createClass';

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ class_code: classNameInput, name: classNameInput })
        };
        const response = await fetch(newClassFunc, requestOptions);
        if (!response.ok) return;

        if (accountType === 'student') {
            const data = await response.json();
            if (data.alertMessage.length > 0) {
                alert(data.alertMessage);
                return;
            }
        }

        setOpenInput(false);
        getClasses();
    }

    async function addCollectionToClasses() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                collection_id: props.collectionForClass.id,
                class_ids: chosenClassrooms.map(({ id }) => id).filter(i => i !== -1) // Array of chosen classroom ids
            })
        };
        const response = await fetch('/api/addCollectionToClasses', requestOptions);
        if (!response.ok) return;

        props.setCollectionForClass({ id: -1 })
        alert("Sucessfully added")
    }

    function stringOfChosenClassrooms() {
        return chosenClassrooms.map(({ name }) => name).filter(name => name !== undefined).join(', ');
    }

    function classStyle(alreadyAdded, isChosen) {
        if (alreadyAdded) return "already-added";
        if (isChosen) return "chosen-classroom";
        return "classroom";
    }

    return (
        <div className={props.parent === "DeckSelection" ? "ClassroomGrid-overlay" : "c"}>
            <div className="container">
                {(classes.length === 0 || chosenClassrooms.length === 0) ?
                    <div className="no-classes">
                        No classes
                    </div>
                    :
                    <>
                        {props.parent === "DeckSelection" &&
                            <h4 className='instruction'>
                                {insertStringInPhrase(titles[language].select_instruction, [props.collectionForClass.name])}
                            </h4>
                        }
                    </>
                }
                <div className="classrooms-container">
                    {classes.map((classroom, c) =>
                        <div
                            key={c}
                            className={classStyle(classroom.alreadyAdded, chosenClassrooms[c].id > -1)}
                            onClick={() => {
                                if (props.parent === 'DeckSelection') {
                                    if (classroom.alreadyAdded) return;
                                    let chosen = [...chosenClassrooms];
                                    chosen[c] = chosen[c].id === -1 ? classroom : { id: -1 };
                                    setChosenClassrooms(chosen);
                                    return;
                                }
                                props.setChosenClassroom(classroom);
                                sessionStorage.setItem('classroom', JSON.stringify(classroom));
                            }}
                        >
                            <div className="name">
                                {classroom.name}
                            </div>
                            <div className="code">
                                {titles[language].class_code}: <b>{classroom.class_code}</b>
                            </div>
                            {classroom.teacher}
                            {classroom.studentAmount}
                            {props.parent === "DeckSelection" && classroom.alreadyAdded &&
                                <div className='already-added-label'>Already added</div>
                            }
                        </div>
                    )}
                </div>
                {props.parent === "DeckSelection" &&
                    <div className='addCollectionToClass'>
                        <h4 className='instruction'>
                            {insertStringInPhrase(titles[language].add_collections_to_class, [props.collectionForClass.name, stringOfChosenClassrooms()])}
                            {stringOfChosenClassrooms().length === 0 && <>..</>}
                        </h4>
                        <button
                            className='confirm'
                            disabled={stringOfChosenClassrooms().length === 0}
                            onClick={() => addCollectionToClasses()}
                        >
                            Confirm
                        </button>
                    </div>
                }
                <div className="create-class">
                    {!openInput ?
                        <button onClick={() => setOpenInput(true)}>
                            {accountType === "teacher" ? <>{titles[language].create_class}</> : <>{titles[language].join_class}</>}
                        </button>
                        :
                        <>
                            <FontAwesomeIcon
                                icon={faArrowLeft}
                                className="back"
                                onClick={() => setOpenInput(false)}
                            />
                            <input
                                className='class-input'
                                placeholder={accountType === "teacher" ? titles[language].class_name : titles[language].class_code}
                                value={classNameInput}
                                onChange={handleChange}
                                onKeyPress={event => ((event.keyCode || event.which === 13) && classNameInput.length > 0) && newClassroom()}
                            >
                            </input>
                            <button
                                onClick={() => {
                                    console.log(accountType)
                                    if (classNameInput.length > 0) {
                                        newClassroom();
                                        return;
                                    }
                                    alert("Fill in the blank input.");
                                }}
                            >
                                {accountType === "teacher" ? <>{titles[language].create_class}</> : <>{titles[language].join_class}</>}
                            </button>
                        </>
                    }
                    {props.parent === "DeckSelection" &&
                        <button className="cancel" onClick={() => props.setCollectionForClass({ id: -1 })}>
                            {titles[language].cancel}
                        </button>
                    }
                </div>
            </div>
        </div>
    );
}

export default ClassroomGrid;