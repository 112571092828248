import React from 'react';
import { Link } from "react-router-dom";
import Label from './util/Label';
import Explanation from './util/Explanation';
import FlashcardForms from './util/FlashcardForms';
import './styles/Quiz.scss';
import './styles/FlashcardForms.scss';
import titles from '../data/titles.json';
import linguisticInfo from '../data/linguisticInfo.json';
// import { useSession } from './SessionContext';

// Alert message if leaving, say that progress won't be saved
class Quiz extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			correct: 0,
			incorrect: 0,
			points: 0,

			randomType: "",
			answered: null,
			showChoices: false,
			questionNumber: -1,
			wrongAnswer: "",

			history: [],

			result: null,
			choices: [],
			chosenStatus: [],
			choice: "",
		};
		this.processResult = this.processResult.bind(this);
		this.nextQuestion = this.nextQuestion.bind(this);
		this.getFlashcardFromDeck = this.getFlashcardFromDeck.bind(this);
		this.getRandomFlashcard = this.getRandomFlashcard.bind(this);
		this.mixMultipleChoice = this.mixMultipleChoice.bind(this);
		this.saveChoice = this.saveChoice.bind(this);
		this.setChoice = this.setChoice.bind(this);
		this.setAnswered = this.setAnswered.bind(this);
		this.getQuestionType = this.getQuestionType.bind(this);
	}

	async componentDidMount() {
		if (this.props.location.chosenDeck) { // If there is data to make a quiz with
			const { flashcards, quizType } = this.props.location;

			// console.log(flashcards);
			this.setState({
				history: Array(flashcards.length).fill({
					correct: 0, attempt: "unattempted", id: -1, quiz_type: quizType
				})
			});
			this.nextQuestion();
		} else {
			window.location.replace("/home"); // Redirects to homepage on reload
			alert("Your quiz progress has not been saved.");
		}
	}

	// Mix up multiple choice order
	mixMultipleChoice(choices) {
		for (let j = choices.length - 1; j > 0; j--) {
			const k = Math.floor(Math.random() * (j + 1));
			[choices[j], choices[k]] = [choices[k], choices[j]];
		}
		return choices;
	}

	getRandomFlashcard() {
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				form: this.props.location.tagForm
			})
		};
		fetch('/getQuestion', requestOptions)
			.then(response => response.json())
			.then(data => {
				console.log(data);

				let choicesClone = this.mixMultipleChoice([data.choices[0].verb, data.choices[1].verb, data.choices[2].verb]);

				this.setState({
					tense: data.answer.tense_tag,
					infinitive: data.answer.root_tag,
					conjuagtedVerb: data.answer.verb,
					choices: choicesClone,
				});
			});
	}

	getFlashcardFromDeck() {
		const { chosenDeck, flashcards, quizType } = this.props.location;
		const { questionNumber, randomType } = this.state;

		// console.log(questionNumber);

		/* If this quiz needs a tense value and the verb in question doesn't have one, skip the question
		if (flashcards.tense[i] === null) {
			this.setState({i: i + 1}); AWAIT
		}*/

		// If the deck hasn't been gone through yet
		if (questionNumber < flashcards.length) {
			// If the question is asking to conjugate the verb
			if ((quizType === "conjugate") || (randomType === "conjugate")) {
				let choicesClone = []

				const requestOptions = {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({
						infinitive: flashcards[questionNumber].infinitive
					})
				};
				fetch('/api/getWrongChoices', requestOptions)
					.then(response => response.json())
					.then(data => {
						// console.log(data);

						choicesClone = this.mixMultipleChoice([data.choices[0].verb, data.choices[1].verb, flashcards[questionNumber].verb]);

						this.setState({ choices: choicesClone });

						//console.log("ANSWER: ", flashcards[questionNumber].verb);
					}
					);
			} // If question is asking for the tense of a conjugated verb
			else if ((quizType === "identify_tense") || (randomType === "identify_tense")) {
				let choicesClone = [];
				let randomTenseIndex;

				choicesClone.push(flashcards[questionNumber].tense);

				// Get wrong answers
				while (choicesClone.length !== 3) {
					randomTenseIndex = Math.floor(Math.random() * Math.floor(linguisticInfo.tense.length));
					choicesClone.push(linguisticInfo.tense[randomTenseIndex].tag_name);
					choicesClone = [...new Set(choicesClone)]; // Remove duplicate values
				}

				choicesClone = this.mixMultipleChoice(choicesClone); // Mix up the values
				// console.log(choicesClone);

				this.setState({
					choices: choicesClone,
					showChoices: true
				});

				//console.log("ANSWER: ", flashcards[questionNumber].tense);
			}
		}
	}

	// Process data from the child that describes if the user got the answer right
	processResult(result, wrongAnswer) { // change wrongAnswer variable name to potentialAnswer (seperate from state version)
		return new Promise((resolve, reject) => {
			const { flashcards, quizType } = this.props.location;
			const { questionNumber, randomType } = this.state;

			let chosenStatusClone = this.state.chosenStatus;
			let currentHistory = [...this.state.history];

			// add quiztype to history
			if (result === true) {
				let rewardedPoints = 100;

				chosenStatusClone[chosenStatusClone.findIndex((status) => status === "checked")] = "correct";

				currentHistory[questionNumber] = {
					correct: 1,
					attempt: wrongAnswer,
					quiz_type: quizType === "mixture" ? randomType : quizType,
					...flashcards[questionNumber]
				};

				if (quizType === "mixture") currentHistory[questionNumber]['quizType'] = this.state.randomType;

				if ((quizType === "conjugate" || this.state.randomType === "conjugate") && this.state.showChoices) rewardedPoints = 50;

				this.setState({
					wrongAnswer: "",
					correct: this.state.correct + 1,
					points: this.state.points + rewardedPoints,
					chosenStatus: chosenStatusClone,
					history: [...currentHistory]
				});

				resolve(currentHistory);
			} else if (result === false) {
				let answer;

				currentHistory[questionNumber] = {
					correct: 0,
					attempt: wrongAnswer.length > 0 ? wrongAnswer : "skipped",
					quiz_type: quizType === "mixture" ? randomType : quizType,
					...flashcards[questionNumber]
				};

				if (quizType === "mixture") currentHistory[questionNumber]['quizType'] = this.state.randomType;

				if ((quizType === "conjugate") || (this.state.randomType === "conjugate")) {
					answer = flashcards[questionNumber].verb;
				}

				else if ((quizType === "identify_tense") || (this.state.randomType === "identify_tense")) {
					answer = flashcards[questionNumber].tense;
				}

				for (var i = 0; i < chosenStatusClone.length; i++) {
					if ((chosenStatusClone[i] === "unchecked") && (this.state.choices[i] === answer)) {
						chosenStatusClone[i] = "correct";
					}
				}

				chosenStatusClone[chosenStatusClone.findIndex((status) => status === "checked")] = "incorrect";
				this.setState({
					wrongAnswer: wrongAnswer,
					incorrect: this.state.incorrect + 1,
					chosenStatus: chosenStatusClone,
					history: [...currentHistory]
				});
				resolve(currentHistory);
			}
		})
	}

	// Saves what the user chose from the multiple-choices
	saveChoice(c) {
		let chosenStatusClone = ["unchecked", "unchecked", "unchecked"];
		chosenStatusClone[c] = "checked";

		this.setState({
			chosenStatus: chosenStatusClone,
			choice: this.state.choices[c]
		});

		console.log(this.state.choices[c])
	}

	setChoice(newChoice) { this.setState({ choice: newChoice }) }
	setAnswered(isAnswered) { this.setState({ answered: isAnswered }) }

	getQuestionType() { // For mixture
		const flashcard = this.props.location.flashcards[this.state.questionNumber + 1];
		let questionType = "";

		questionType = Math.floor(Math.random() * 2) === 0 ? "conjugate" : "identify_tense";

		// if (flashcard.mood !== "Indicative" &&
		// 	flashcard.mood !== "Subjunctive" &&
		// 	flashcard.mood !== "Conditional" &&
		// 	flashcard.mood !== "Imperative") {
		// 	questionType = "identify_tense";
		// } else {
		// 	questionType = "conjugate";
		// }

		return questionType;
	}

	// Goes to the next question in the quiz
	nextQuestion() {
		const { quizType } = this.props.location;

		// console.log(this.state.history)

		this.setState({
			choice: "",
			explainPage: 0,
			wrongAnswer: "",
			showChoices: quizType === "conjugate" ? false : true,
			answered: false,
			questionNumber: this.state.questionNumber + 1,
			chosenStatus: ["unchecked", "unchecked", "unchecked"],
			randomType: quizType === "mixture" ? this.getQuestionType() : "none"
		}, () => {
			this.getFlashcardFromDeck();
			if (quizType === "mixture") {
				this.setState({
					showChoices: this.state.randomType === "conjugate" ? false : true
				})
			}
		});
	}

	render() {
		const { quizType, tagForm, chosenDeck, flashcards, language, chosenClassroom } = this.props.location;
		const { questionNumber, answered, history, points, randomType, choices, choice, showChoices } = this.state;

		let resultData = {
			pathname: "/results",
			chosenClassroom: chosenClassroom,
			history: history,
			flashcards: flashcards,
			quizType: quizType,
			chosenDeck: chosenDeck,
			class_id: this.props.location.class_id,
			parent: this.props.location.parent,
			correct: this.state.correct,
			points: points
		}

		return (
			<div className="Quiz">
				{chosenDeck !== undefined && questionNumber > -1 &&
					<>
						<div className="container">
							<div className="quizhead">
								<h3 className='deck-name'>{tagForm}{chosenDeck.name}</h3>
								<div className="stats">
									<div className='questions-done'>{questionNumber + 1} / {flashcards.length}</div>
									<div className='score'>{titles[language].score}: {points}</div>
								</div>
								{((quizType === "conjugate" || randomType === "conjugate") && !showChoices) ?
									<button className='hint' onClick={() => this.setState({ showChoices: true })}>
										{titles[language].hint}
									</button>
									:
									<div className='hint'></div>
								}
							</div>
							<div className="question">
								{this.state.wrongAnswer.length > 0 &&
									<Explanation
										quizType={quizType}
										flashcards={flashcards}
										questionNumber={questionNumber}
										randomType={randomType}
										wrongAnswer={this.state.wrongAnswer}
									/>
								}
								<div className="card-container">
									<div className="FlashcardForms">
										<div className={randomType === "none" ? quizType : randomType}>
											<FlashcardForms
												verb={flashcards[questionNumber].verb}
												infinitive={flashcards[questionNumber].infinitive}
												tags={{
													tense: flashcards[questionNumber].tense,
													mood: flashcards[questionNumber].mood,
													person: flashcards[questionNumber].person,
													number: flashcards[questionNumber].number
												}}
												flashcardMode={randomType === "none" ? quizType : randomType}
												choice={choice}
												parent="Quiz"
												answered={answered}
												questionNumber={questionNumber}
												questionAmount={flashcards.length}
												processResult={this.processResult}
												setAnswered={this.setAnswered}
												nextQuestion={this.nextQuestion}
											/>
										</div>
									</div>
								</div>
								{showChoices &&
									<ul className="choices">
										{choices.map((choice_, c) =>
											<li key={c} onClick={() => !answered && this.saveChoice(c)}>
												<div className={this.state.chosenStatus[c]}>
													{(quizType === "conjugate" || randomType === "conjugate") && `${choice_}`}
													{(quizType === "identify_tense" || randomType === "identify_tense") && <Label tags={{ tense: choice_ }} />}
												</div>
											</li>
										)}
									</ul>
								}
							</div>
							<div className="continue">
								{(quizType === "identify_tense" || randomType === "identify_tense") && choice.length > 0 && !answered &&
									<button onClick={() => this.setState({ answered: true })}>
										{titles[language].check}
									</button>
								}
								{(quizType === "conjugate" || randomType === "conjugate") && !answered &&
									<button onClick={() => this.setState({ answered: true })}>
										{titles[language].check}
									</button>
								}
								{answered && questionNumber < flashcards.length - 1 &&
									<button
										className="next"
										onClick={() => this.nextQuestion()}
									>
										{titles[language].next}
									</button>
								}
								{history[history.length - 1].id !== -1 && answered && questionNumber === flashcards.length - 1 &&
									<Link to={resultData}>
										<button>{titles[language].finish}</button>
									</Link>
								}
							</div>
						</div>
						<div className='quiz-footer'>
							<Link to={resultData}>
								<button className='exitQuiz'>Exit Quiz</button>
							</Link>
						</div>
					</>
				}
			</div>
		);
	}
}

export default Quiz;