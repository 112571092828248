import React from 'react';
import './styles/Nav.scss';
import { NavLink } from 'react-router-dom';
import titles from '../data/titles.json';
import { useSession, useSessionUpdate } from './SessionContext';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faUserCircle } from '@fortawesome/free-solid-svg-icons'

const Nav = () => {
	const { language, setLanguage, username, isLoggedIn } = useSession();
	const { handleLogout } = useSessionUpdate();

	return (
		<nav className="Nav">
			<NavLink to="/home" className="logo">Card-IT</NavLink>
			<span className="menu" style={isLoggedIn ? {} : { display: 'none' }}>
				<NavLink to="/home" exact activeClassName="selected">{titles[language].home}</NavLink>
				<NavLink to="/classroom" exact activeClassName="selected">Classroom</NavLink>
				<NavLink to="/analyze" exact activeClassName="selected">{titles[language].verb_analyzer}</NavLink>
				<NavLink to="/search" exact activeClassName="selected">{titles[language].verb_search}</NavLink>
				<NavLink to="/linguistics" exact activeClassName="selected">{titles[language].linguistics}</NavLink>
				<NavLink to="/feedback" exact activeClassName="selected">Feedback</NavLink>
			</span>
			<span className="options">
				<NavLink
					to="/account"
					id="account"
					style={isLoggedIn ? {} : { display: 'none' }}
				>
					{username}
				</NavLink>
				<select
					value={language}
					onChange={e => setLanguage(e.target.value)}
				>
					<option value="tag_en">English</option>
					<option value="tag_de">German</option>
				</select>
				<button
					style={isLoggedIn ? {} : { display: 'none' }}
					onClick={() => handleLogout().catch(error => console.error("Fetch operation failed.", error))}
				>
					{titles[language].logout}
				</button>
			</span>
		</nav>
	);
}

export default Nav;