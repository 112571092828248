import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import titles from '../data/titles.json';
import './styles/MiniDeckSelection.scss';
import { useSession, useSessionUpdate } from './SessionContext';

const MiniDeckSelection = (props) => {
    const [saveMessage, setSaveMessage] = useState("");
    const [openCloseArrow, setOpenCloseArrow] = useState(faCaretUp);
    const [chosenCollectionOption, setChosenCollectionOption] = useState(0);

    const [copyToDeck, setCopyToDeck] = useState({ id: -1 }); // Only this is used when parent is Home

    const { language } = useSession();
    const { insertStringInPhrase } = useSessionUpdate();

    // Sets default collection
    useEffect(() => {
        if ((props.collections.user[0] !== undefined) && // Data has loaded
            (props.chosenDeck.id !== -1) && // A deck has been chosen (from Add cards button)
            (props.chosenUserID === -1)) { // We are not exploring
            setChosenCollectionOption(props.collections.user.findIndex(collection => collection.id === props.chosenDeck.collection_id));
        }
    }, [props.chosenDeck, props.collections])

    // Adds or deletes cards from a deck
    async function cardOperations(addOrDeleteFunc) {
        // If we are copying cards choose the id of the deck we are copying to
        let deck_id = props.parent === "Home" && addOrDeleteFunc === "/api/addCards" ? copyToDeck.id : props.chosenDeck.id;

        if (props.chosenCards.length > 0 && deck_id > -1) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    cards: props.chosenCards,
                    deck_id: deck_id
                })
            };
            const response = await fetch(addOrDeleteFunc, requestOptions);
            if (!response.ok) return;
            const data = await response.json();
            if (data['error'] !== undefined) {
                alert(data['error']);
                return;
            }
            if (data['someAdded'] !== undefined) alert(data['someAdded']);

            // Refresh
            props.parent === "Home" && props.getFlashcards(props.chosenDeck); // Just for passing the same id
            // props.setChosenCards([]);
            props.getCollections();

            addOrDeleteFunc === "/api/addCards"
                ? setSaveMessage("Saved!")
                : setSaveMessage(`Deleted ${props.chosenCards.length} cards from ${props.chosenDeck.name}!`);

            setInterval(() => { setSaveMessage("") }, 5000);
        }
        // else Choose a deck and some cards
    }

    let decksInCollection = [];

    // Wait for attributes to be defined, maybe await this later?
    if (props.collections.user[0] !== undefined) {
        decksInCollection = props.decks.user.filter(deck => deck.collection_id === props.collections.user[chosenCollectionOption].id);
    }

    return (
        <div className="DeckSelection">
            <div className="addCards">
                {saveMessage.length > 0 &&
                    <div className="saveMessage">{saveMessage}</div>
                }
                <div
                    className="deckSelection"
                    style={openCloseArrow === faCaretDown ? {} : { display: 'none' }}
                >
                    <select
                        className="form-select"
                        value={chosenCollectionOption}
                        onChange={e => setChosenCollectionOption(e.target.value)}
                    >
                        {props.collections.user.map((collection, c) =>
                            <option key={c} value={c}>
                                {collection.name}
                            </option>
                        )}
                    </select>
                    <ul className="deckList">
                        {decksInCollection.map((deck, d) =>
                            <li key={d}>
                                <div
                                    className="unchecked"
                                    style={{
                                        backgroundColor:
                                            (props.parent === "Home" || props.parent === "Classroom") ?
                                                copyToDeck.id === deck.id && "#e3dafd" :
                                                props.chosenDeck.id === deck.id && "#e3dafd"
                                    }}
                                    onClick={() => {
                                        if (props.parent === "Home" || props.parent === "Classroom") setCopyToDeck(deck);
                                        else props.setChosenDeck(deck);
                                    }}
                                >
                                    {deck.name} ({deck.size})
                                </div>
                            </li>
                        )}
                    </ul>
                    <div className="newDeck">
                        <input
                            placeholder={titles[language].new_deck}
                            name="deckName"
                            value={props.deckName}
                            onChange={e => props.setDeckName(e.target.value)}
                            onKeyPress={e => (e.keyCode || e.which === 13) && props.add(props.collections.user[chosenCollectionOption].id, props.deckName, 'deck')}
                        />
                        <FontAwesomeIcon
                            icon={faPlusCircle}
                            title="Add Deck"
                            className="addDeck"
                            onClick={() => props.add(props.collections.user[chosenCollectionOption].id, props.deckName, 'deck')}
                        />
                    </div>
                </div>
                <div className="btn-group">
                    <button
                        className={props.chosenCards.length > 0 ? "addTo" : "unactiveAddTo"}
                        disabled={props.chosenCards.length === 0}
                        onClick={() => cardOperations("/api/addCards")}
                    >
                        {(props.parent === "Home" || props.parent === "Classroom") && // From deckname copy (card amount) to otherdeck
                            <>
                                From {props.chosenDeck.name}&nbsp;
                                {titles[language].copy_to.substr(0, titles[language].copy_to.indexOf(' '))} ({props.chosenCards.length})&nbsp;
                                {titles[language].copy_to.substr(titles[language].copy_to.indexOf(' ') + 1)}&nbsp;
                                {copyToDeck.name}
                            </>
                        }
                        {(props.parent === "Search" || props.parent === "VerbAnalyzer") && // Add (card amount) to deck
                            <>
                                {titles[language].add_to.substr(0, titles[language].add_to.indexOf(' '))} ({props.chosenCards.length})&nbsp;
                                {titles[language].add_to.substr(titles[language].add_to.indexOf(' ') + 1)}&nbsp;
                                {props.chosenDeck.name}
                            </>
                        }
                    </button>
                    <button
                        className="openSelection"
                        onClick={() => openCloseArrow === faCaretUp ? setOpenCloseArrow(faCaretDown) : setOpenCloseArrow(faCaretUp)}
                    >
                        <FontAwesomeIcon icon={openCloseArrow} title="Change Deck" />
                    </button>
                </div>
            </div>
            {(props.parent === "Home" && props.chosenUserID === -1) &&
                <div className="deleteCards">
                    <button
                        className="deleteFrom"
                        onClick={() => cardOperations("/api/deleteCards")}
                    >
                        {language === "tag_en" && insertStringInPhrase(titles[language].delete_from, [props.chosenCards.length, props.chosenDeck.name])}
                        {language === "tag_de" && insertStringInPhrase(titles[language].delete_from, [props.chosenDeck.name, props.chosenCards.length])}
                    </button>
                </div>
            }
        </div>
    )
}

export default MiniDeckSelection;