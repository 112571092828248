import React, { useEffect, useState } from 'react'
import FlashcardForms from './FlashcardForms';
import Label from './Label';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';

function Explanation(props) {
    const [explanation, setExplanation] = useState({});
    const [explainPage, setExplainPage] = useState(0);

    useEffect(() => {
        getExplanation();
    }, [])

    function getExplanation() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                wrongAnswer: props.wrongAnswer,
                quizType: props.quizType === "mixture" ? props.randomType : props.quizType, // CHOOSE ONE VERSION OF THIS CONDITIONAL (flashcard forms conditional)
                infinitive: props.flashcards[props.questionNumber].infinitive
            })
        };
        fetch('/api/getExplanation', requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log(data);

                let explanationClone = { tense: [], mood: [], person: [], number: [], infinitive: [], verb: [], id: [] };

                // Loop through all the generated verbs
                for (let i = 0; i < data.explanation.length; i++) {
                    // Store attributes of current verb form
                    explanationClone.tense[i] = data.explanation[i].tense_tag;
                    explanationClone.mood[i] = data.explanation[i].mood_tag;
                    explanationClone.person[i] = data.explanation[i].person_tag;
                    explanationClone.number[i] = data.explanation[i].number_tag;
                    explanationClone.infinitive[i] = data.explanation[i].root_tag;
                    explanationClone.verb[i] = data.explanation[i].verb;
                    explanationClone.id[i] = data.explanation[i].id;
                }

                setExplanation(explanationClone);
            }
            );
    }

    // Navigates the explanation gallery
    function navExplainGallery(movement) {
        if ((explainPage + movement) < explanation.id.length &&
            (explainPage + movement) > -1) {
            setExplainPage(explainPage + movement);
        }
    }

    var explanationGallery = [];

    // Generates the explanation gallery
    if (explanation.id) {
        for (let i = 0; i < explanation.id.length; i++) {
            explanationGallery.push(
                <div className="gallery" key={i}>
                    <div className="FlashcardForms">
                        <div className="raw">
                            <FlashcardForms
                                verb={explanation.verb[i]}
                                infinitive={explanation.infinitive[i]}
                                tags={{
                                    tense: explanation.tense[i],
                                    mood: explanation.mood[i],
                                    person: explanation.person[i],
                                    number: explanation.number[i]
                                }}
                                flashcardMode="raw"
                            />
                        </div>
                    </div>
                </div>
            );
        }
    }

    return (
        <div className="explanation">
            {((props.quizType === "conjugate") || (props.randomType === "conjugate")) &&
                <div>
                    {explanation?.verb?.length === 0 ?
                        <div><b>{props.wrongAnswer}</b> is not a verb form or is spelled wrong.</div>
                        :
                        <div>
                            <b>{props.wrongAnswer}</b> can be:
                            <br />{explanationGallery[explainPage]}
                        </div>
                    }
                </div>
            }
            {((props.quizType === "identify_tense") || (props.randomType === "identify_tense")) &&
                <div>
                    <b>{props.flashcards[props.questionNumber].infinitive}</b> in <b> <Label tags={{ tense: props.wrongAnswer }} /></b> can be:
                    <br />{explanationGallery[explainPage]}
                </div>
            }
            {explanationGallery.length > 0 &&
                <div className="pages">
                    <FontAwesomeIcon
                        icon={faArrowCircleLeft}
                        title="Previous"
                        className="previousButton"
                        onClick={() => navExplainGallery(-1)}
                    />
                    <b>{explainPage + 1} / {explanationGallery.length}</b>
                    <FontAwesomeIcon
                        icon={faArrowCircleRight}
                        title="Next"
                        className="nextButton"
                        onClick={() => navExplainGallery(1)}
                    />
                </div>
            }

        </div>
    )
}

export default Explanation