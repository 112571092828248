import React from 'react';
import './styles/Linguistics.scss';

function Linguistics() {
    return (
        // Please reconsider the styling of this page, I was just playing around with it
        <div className="Linguistics">
            <div className="container">
                <p className="underline"><strong><span>Tense</span></strong></p>
                <p><strong>Tense</strong> is a grammatical means to indicate the time when an action or event occurs. As such, tense expresses whether an action or event happened in the past (past tense), will happen in the future (future tense) or is happening right now (present tense).</p>
                <p>The Italian language comprises the following tenses:</p>
                <ul className='list'>
                    <li><p>Presente (Present Tense)</p></li>
                    <li><p>Passato Prossimo (Present Perfect)</p></li>
                    <li><p>Imperfetto (Imperfect)</p></li>
                    <li><p>Futuro Semplice (Simple Future)</p></li>
                    <li><p>Futuro Anteriore (Future Perfect Tense)</p></li>
                    <li><p>Trapassato Prossimo (Past Perfect)</p></li>
                    <li><p>Passato Remoto (Historic Past)</p></li>
                    <li><p>Trapassato Remoto (Historic Past Perfect)</p></li>
                </ul>
                <p className="underline"><strong><span>Mood</span></strong></p>
                <p><strong>Mood</strong> is a grammatical means that allows speakers to express their attitude towards their utterance (e.g., a statement of fact, of desire, of command, etc.). Italian has 7 verbal moods (modi in italiano):</p>
                <ul className='subtitle'>
                    <li><p><strong>Modo indicative (Indicative)</strong></p></li>
                </ul>
                <p>The Indicative mood is used to make factual and declarative statements or ask questions. Using the indicative mood expresses ones believe of the statement as being unequivocally true, e.g.,<br />
                    la palla <strong>è</strong> rotonda (the ball <strong>is</strong> round). The Indicative is composed with all Italian tenses, allowing one to talk about the present, past and future.</p>
                <ul className='subtitle'>
                    <li><p><strong>Modo Condizionale (Conditional)</strong></p></li>
                </ul>
                <p>The Conditional is used to express the possibility of something happening or a hypothetical. It may be used to express desires, doubts or requests. In Italian, the Conditional can be in present and past tense:</p>
                <div className='indent'>
                    <ul className='subtitle'>
                        <li><p>Condizionale Presente</p></li>
                    </ul>
                    <blockquote>
                        <p>The Condizionale Presente expresses potential or possible actions, desires, doubts, requests and suggestions.</p>
                    </blockquote>
                    <ul className='subtitle'>
                        <li><p>Condizionale Passato</p></li>
                    </ul>
                    <blockquote>
                        <p>The Condizionale Passato corresponds in its functionality to the Condizionale Presente, with the difference of referring to desires, doubts, etc. in the past. Additionally, it may be used to express impossible actions, unofficial news , and hypothetical phrases.</p>
                    </blockquote>
                </div>
                <p>For more information and examples, see here: <a href="https://italianonline.org/learn/italian-grammar/italian-conditional-mode/" target="_blank" rel="noopener noreferrer" className="uri">https://italianonline.org/learn/italian-grammar/italian-conditional-mode/</a></p>
                <ul className='subtitle'>
                    <li><p><strong>Modo Congiuntivo (Subjunctive)</strong></p></li>
                </ul>
                <p>The Congiuntivo is used when a main clause expressing hopes, opinions, wishes or doubts is combined with a subordinate clause, e.g.,<br />
                    Penso <strong>che</strong> tu <strong>abbia</strong> ragione - I think that you are right.</p>
                <p>The conjunction che (that) signals the following subordinate clause. Since the main clause expresses an opinion (I think), the verb in the subordinate clause is marked in the congiuntivo.</p>
                <p>In general, subjective or personal statements are expressed with the congiuntivo. The Italian language has four subjunctive tenses:</p>
                <ul className='list'>
                    <li><p>Congiuntivo Presente (Present Subjunctive)</p></li>
                    <li><p>Congiuntivo Passato (Past Subjunctive)</p></li>
                    <li><p>Congiuntivo Imperfetto (Imperfect Subjunctive)</p></li>
                    <li><p>Congiuntivo Trapassato (Past Perfect Subjunctive)</p></li>
                </ul>
                <p>For more details and examples, see here: <a href="https://italianonline.org/learn/italian-grammar/italian-subjunctive-mode/" target="_blank" rel="noopener noreferrer" className="uri">https://italianonline.org/learn/italian-grammar/italian-subjunctive-mode/</a></p>
                <ul className='subtitle'>
                    <li><p><strong>Modo Imperativo (Imperative)</strong></p></li>
                </ul>
                <p>The imperative is used when expressing an order or command to someone, and thus, it does not have the first an third singular persons (io, lui, lei):</p>
                <p>Guarda! - Look!</p>
                <p>Leggete qua! - Read here (you all)!</p>
                <p>More: <a href="https://italianonline.org/learn/italian-grammar/italian-imperative-mode/" target="_blank" rel="noopener noreferrer" className="uri">https://italianonline.org/learn/italian-grammar/italian-imperative-mode/</a></p>
                <ul className='subtitle'>
                    <li><p><strong>Modo Gerundio (Gerund)</strong></p></li>
                </ul>
                <p>The gerund expresses the time, cause or condition of an action that is happening either at the same time as the action expressed by the main verb or before that, e.g.:</p>
                <p><strong>Parlando</strong> con il professore ho capito di aver sbagliato - While speaking with the professor I realized that I had made a mistake.</p>
                <p>Ieri, <strong>avendo</strong> parlato di cibo, mi sono messo a cucinare - Yesterday, having spoken of food, I started cooking.</p>
                <p>The gerund has a present (gerundio presente, example sentence 1) and a past form (gerundio passato, example sentence 2).</p>
                <p>More information: <a href="https://italianonline.org/learn/italian-grammar/italian-gerund-mode/" target="_blank" rel="noopener noreferrer" className="uri">https://italianonline.org/learn/italian-grammar/italian-gerund-mode/</a></p>
                <ul className='subtitle'>
                    <li><p><strong>Modo Participio (Participle)</strong></p></li>
                </ul>
                <p>The participle, in contrast to the other Moods, is used to connect the noun or adjective with the action. It has a present and a past tense:</p>
                <p>Non ho mai visto un cane <strong>parlante</strong> - (I've never seen a talking dog)</p>
                <p>L'Italiano parlato è diverso da dello scritto - Spoken Italian is different from the written one.</p>
                <p>The Participio Passato (Past Participle) together with the auxiliary verbs essere and avere creates past tenses like the passato prossimo:</p>
                <p>Ho <strong>mangiato</strong> una pizza - I ate a pizza</p>
                <p>Sono <strong>andato</strong> al cinema - I went to the cinema</p>
                <p>Additionally, it can be used in a similar way as adjectives:</p>
                <p>La finestra <strong>aperta</strong> - the open window</p>
                <p>More information: <a href="https://italianonline.org/learn/italian-grammar/italian-participle-mode/" target="_blank" rel="noopener noreferrer" className="uri">https://italianonline.org/learn/italian-grammar/italian-participle-mode/</a></p>
                <ul className='subtitle'>
                    <li><p><strong>Modo Infinito (Infinitive)</strong></p></li>
                </ul>
                <p>The infinitive expresses an action or state without any reference to a subject. It is not marked for person, number, or tense. Instead, it consists of the base of the verb and is usually the form listed in dictionaries. Italian infinitives either end on -are, -ere or -ire, forming three conjugation classes. E.g.:</p>
                <p>am<strong>are</strong></p>
                <p>prend<strong>ere</strong></p>
                <p>fin<strong>ire</strong></p>
                <p className="underline"><strong><span>Person</span></strong></p>
                <p>The grammatical category <strong>person</strong> distinguishes between references to participants in an event. It distinguishes between the speaker of an utterance (first person), the addressee (second person), and others (third person). In other words, first person includes the speaker (Engl. I, we, me, and us), second person is the person or people spoken to (you), and third person includes all others (Engl. he, she it, they, them, etc.).</p>
                <p className="underline"><strong><span>Number</span></strong></p>
                <p><strong>Number</strong> expresses count distinctions (singular and plural) and with respect to verbs, number interacts with person. E.g., number is responsible for the distinction between first person I (singular) and we (plural).</p>
                <p>The interaction of these four categories (tense, mood, person and number) determine the form of verbs:</p>
                <p>Examples:</p>
                <p>Verb: amare, conjugated in:</p>
                <ul className='list'>
                    <li><p>Tense = present</p></li>
                    <li><p>Mood = indicative</p></li>
                    <li><p>Person = 3</p></li>
                    <li><p>Number = Pl</p></li>
                </ul>
                <p>corresponds to amiamo.</p>
                <p>In most cases, changing the value of one category leads to a different verb form:</p>
                <ul className='list'>
                    <li><p>Tense = present</p></li>
                    <li><p>Mood = indicative</p></li>
                    <li><p>Person = 3</p></li>
                    <li><p><strong>Number = Sg</strong></p></li>
                </ul>
                <p>corresponds to ama</p>

            </div>
        </div>
    )
}

export default Linguistics;
