import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import DeckSelection from './DeckSelection';
import titles from '../data/titles.json';
import { useSession } from './SessionContext';

import './styles/Explore.scss';

const Explore = (props) => {
    const [users, setUsers] = useState([]);
    const { language } = useSession();

    useEffect(() => {
        (async () => {
            const response = await fetch('/api/getUsers');
            const usersData = await response.json();
            setUsers(usersData.users);
        })();
    }, []);

    return (
        <>
            <div className="Explore">
                <div className="head">
                    {props.chosenUserID > -1 &&
                        <FontAwesomeIcon
                            icon={faArrowCircleLeft}
                            title="Back"
                            onClick={() => props.setChosenUserID(-1)}
                        />
                    }
                    <h2 className="title">{titles[language].explore}</h2>
                </div>
                {props.chosenUserID === -1 &&
                    <ul className="listOfUsers">
                        {
                            users.map((user, u) =>
                                <li key={u}
                                    onClick={() => props.setChosenUserID(user.id)}
                                >
                                    <span className="username">{user.username}</span>
                                    <span className="numberOf">Collections: <b>{user.numberOfCollections}</b></span>
                                    <span className="numberOf">Decks: <b>{user.numberOfDecks}</b></span>
                                </li>
                            )
                        }
                    </ul>
                }
            </div>
            {props.chosenUserID > -1 &&
                <DeckSelection
                    chosenUserID={props.chosenUserID}
                    parent="Home"
                />
            }

        </>
    );
}

export default Explore;