import React from 'react';

const GeneratePronoun = (props) => { 
    let possiblePronouns;
    let pronoun;

    switch (props.person) { // Narrow down the possible singular or plural pronouns from the person
        case "1P":
            possiblePronouns = [<>io</>, <>noi</>];
            break;
        case "2P":
            possiblePronouns = [<>tu</>, <>voi/Voi<sub>formal</sub></>];
            break;
        default:
            possiblePronouns = [<>lui/lei/Lei<sub>formal</sub></>, <>loro/Loro<sub>formal</sub></>];
            break;
    }

    switch (props.number) { // Choose the right pronoun based whether its singular or plural
        case "Sg":
            pronoun = possiblePronouns[0];
            break;
        default:
            pronoun = possiblePronouns[1];
            break;
    }

    return <>({pronoun})</>;
}

export default GeneratePronoun; 