import React, { useEffect, useState } from 'react';
import '../styles/Auth.scss';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { useSession } from '../SessionContext';

function Login(props) {
    const [usernameOrEmail, setUsernameOrEmail] = useState('');
    const [password, setPassword] = useState('');

    const { setUsername, setLanguage, setAccountType, isLoggedIn, setIsLoggedIn } = useSession();

    useEffect(() => {
        if (isLoggedIn) window.location.replace("/home");
    }, []);

    // Login user if their credentials match
    async function handleSuccessfulLogin() {
        if (usernameOrEmail === "" || password === "") {
            alert("Enter all fields.");
            return;
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ usernameOrEmail: usernameOrEmail, password: password })
        };
        const response = await fetch('/api/login', requestOptions);
        const data = await response.json();
        if (!response.ok) {
            alert(data['message']);
            return;
        }

        // if (data.verified === 0) {
        //     alert("Please verify your email.");
        //     return;
        // }

        // If the user exists go to homepage and save user data for this session
        if (data.username != null) {
            console.log(data)
            setUsername(data.username);
            setLanguage(data.lang);
            setAccountType(data.account_type);
            setIsLoggedIn(true);
            props.history.push("/home");
        }
    }

    return (
        <div className="Auth">
            <form>
                <h2>Login</h2>
                <div>
                    <input
                        type="email"
                        value={usernameOrEmail}
                        placeholder="Username or Email"
                        onChange={e => setUsernameOrEmail(e.target.value)}
                        required
                        onKeyPress={event => {
                            if (event.keyCode || event.which === 13) {
                                handleSuccessfulLogin();
                            }
                        }}
                    />
                </div>
                <div>
                    <input
                        type="password"
                        value={password}
                        placeholder="Password"
                        onChange={e => setPassword(e.target.value)}
                        required
                        onKeyPress={event => {
                            if (event.keyCode || event.which === 13) {
                                handleSuccessfulLogin();
                            }
                        }}
                    />
                </div>
                <div>
                    <Button onClick={() => handleSuccessfulLogin()} >
                        Login
                    </Button>
                </div>
                <div className="link">
                    Not registered? <Link to="/register">Create account</Link>
                </div>
            </form>
        </div>
    );
}

export default Login;