import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
//import QuickQuiz from './QuickQuiz';
import Explore from './Explore';
import DeckSelection from './DeckSelection';
import './styles/Home.scss';
import titles from '../data/titles.json';
import { useSession } from './SessionContext';

const Home = () => {
	const [chosenUserID, setChosenUserID] = useState(-1); // -1 defaults to you
	const [isExploring, setIsExploring] = useState(false);

	const { language, username } = useSession();

	return (
		<>
			<div className="Home">
				<div className="view">
					<span
						className="viewOption"
						style={{ backgroundColor: !isExploring && "hsl(255, 90%, 92%)" }}
						onClick={() => {
							setIsExploring(false);
							setChosenUserID(-1);
						}}
					>
						{username}
					</span>
					<span
						className="viewOption"
						style={{ backgroundColor: isExploring && "hsl(255, 90%, 92%)" }}
						onClick={() => setIsExploring(true)}
					>
						{titles[language].explore}
					</span>
				</div>
				{isExploring ?
					<Explore
						isExploring={isExploring}
						setIsExploring={setIsExploring}
						chosenUserID={chosenUserID}
						setChosenUserID={setChosenUserID}
						parent="Home"
					/>
					:
					<DeckSelection
						chosenUserID={chosenUserID}
						parent="Home"
					/>
				}
			</div>
		</>
	);
}

export default Home;