import React from 'react';
import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';
import Login from './auth/Login';
import Register from './auth/Register';
import Nav from './Nav';
import Home from './Home';
import Classroom from './Classroom';
import Analyze from './Analyze';
import Search from './Search';
import Quiz from './Quiz';
import Results from './Results';
import Linguistics from './Linguistics';
import Feedback from './Feedback';
import Account from './Account';
import PrivateRoute from './util/PrivateRoute';
import { SessionContextProvider } from './SessionContext';
import './styles/App.scss';

export default function App() {
    return (
        <SessionContextProvider>
            <BrowserRouter>
                <Nav />
                <Route exact path="/">
                    <Redirect to="/home" />
                </Route>
                <Switch>
                    <Route exact path="/login" render={props => <Login {...props} />} />
                    <Route exact path="/register" component={Register} />
                    <PrivateRoute path="/home" component={Home} />
                    <PrivateRoute path="/classroom" component={Classroom} />
                    <PrivateRoute path="/quiz/:quiz" component={Quiz} />
                    <PrivateRoute path="/results" component={Results} />
                    <PrivateRoute path="/analyze" component={Analyze} />
                    <PrivateRoute path="/search" component={Search} />
                    <PrivateRoute path="/linguistics" component={Linguistics} />
                    <PrivateRoute path="/feedback" component={Feedback} />
                    <PrivateRoute path="/account" component={Account} />
                </Switch>
            </BrowserRouter>
        </SessionContextProvider>
    );
}