import React, { useState, useEffect } from 'react';
import '../styles/Auth.scss';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { useSession } from '../SessionContext';

function Register(props) {
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [language, setLanguage] = useState('tag_en');
    const [accountType, setAccountType] = useState('student');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const { isLoggedIn } = useSession();

    useEffect(() => {
        if (isLoggedIn) window.location.replace("/home");
    }, []);

    async function handleSuccessfulRegister() {
        if (email === "" || username === "" || password === "" || confirmPassword === "") {
            alert("Enter all fields.");
            return;
        }
        if (password !== confirmPassword) {
            alert("Passwords do not match.");
            return;
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: email, username: username, lang: language, account_type: accountType, password: password })
        };
        const response = await fetch('/api/registerUser', requestOptions);
        if (!response.ok) return;
        const data = await response.json();

        if (data['usernameIsTaken'] !== undefined) {
            alert(data['usernameIsTaken']);
            return;
        }
        if (data['comboIsTaken'] !== undefined) {
            alert(data['comboIsTaken']);
            return;
        }

        alert("Account created.");// A verification email has been sent to you, please verify your email so you can access your account.");
        window.location.replace("/login");
    }

    return (
        <div className="Auth">
            <form autoComplete="new-password">
                <h2>Register</h2>
                <div>
                    <label>Email</label>
                    <input
                        type="email"
                        name="email"
                        value={email}
                        placeholder='Enter email'
                        onChange={e => setEmail(e.target.value)}
                        autoComplete="new-password"
                        required
                    />
                </div>
                <div>
                    <label>Username</label>
                    <input
                        type="text"
                        name="username"
                        value={username}
                        placeholder='Enter username'
                        onChange={e => setUsername(e.target.value)}
                        autoComplete="new-password"
                        required
                    />
                </div>
                <div>
                    <label>Join as</label>
                    <select
                        value={accountType}
                        onChange={event => setAccountType(event.target.value)}
                    >
                        <option value="student">Student</option>
                        <option value="teacher">Teacher</option>
                    </select>
                </div>
                <div>
                    <label>Language</label>
                    <select
                        value={language}
                        onChange={event => setLanguage(event.target.value)}
                    >
                        <option value="tag_en">English</option>
                        <option value="tag_de">German</option>
                    </select>
                </div>
                <div>
                    <label>Password</label>
                    <input
                        type="password"
                        name="password"
                        value={password}
                        placeholder="Password"
                        onChange={e => setPassword(e.target.value)}
                        autoComplete="new-password"
                        required
                    />
                </div>
                <div>
                    <label>Confirm Password</label>
                    <input
                        type="password"
                        name="confirmPassword"
                        value={confirmPassword}
                        placeholder="Confirm Password"
                        onChange={e => setConfirmPassword(e.target.value)}
                        autoComplete="new-password"
                        required
                    />
                </div>
                <div>
                    <Button onClick={() => handleSuccessfulRegister()}>Register</Button>
                </div>
                <div className="link">
                    Already a member? <Link to="login">Click to login</Link>
                </div>
            </form>
        </div>
    );
}

export default Register;