import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import FlashcardForms from './util/FlashcardForms';
import './styles/FlashcardGrid.scss';
// import './styles/FlashcardForms.scss';
import useKeyPress from './util/useKeyPress';
import titles from '../data/titles.json';
import { useSession } from './SessionContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import linguisticInfo from '../data/linguisticInfo.json';
import { faArrowCircleLeft, faArrowCircleRight, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const FlashcardGrid = (props) => {
    // console.log(props.flashcards)
    const [selectStatus, setSelectStatus] = useState([]);
    const [tabStatus, setTabStatus] = useState(["selectedTab", "tab"]);

    const [flashcardForm, setFlashcardForm] = useState("");
    const [viewMode, setViewMode] = useState("Grid View");

    const [flashcardNumber, setFlashcardNumber] = useState(0);
    const [showFormFilters, setShowFormFilters] = useState(false);
    const [formFilter, setFormFilter] = useState({ tense: "All", mood: "All", person: "All", number: "All" });
    const [filteredFlashcards, setFilteredFlashcards] = useState(props.flashcards); // Not always filtered may just hold all flashcards

    const { language } = useSession();

    useEffect(() => {
        if (props.parent === "Home" || props.parent === "Classroom") {
            if (tabStatus[1] === "selectedTab") props.setManageFlashcards(true);
            else props.setManageFlashcards(false);
        }
    }, [tabStatus]);

    // Mount
    useEffect(() => {
        if (props.parent === "Home" || props.parent === "Classroom") {
            setFlashcardForm("side1");
            setViewMode("Presentation View");
        } else if (props.parent === "Search" || props.parent === "VerbAnalyzer") {
            setFlashcardForm("raw");
            setViewMode("Grid View");
        }
    }, [props.parent]);

    useEffect(() => {
        setFilteredFlashcards(props.flashcards);
        setSelectStatus(Array(props.flashcards.length).fill("unselected"));
        // if (props.parent !== "Members")
        props.setChosenCards([]);
        // props.flashcards.length > 0 ? setTabStatus(["selectedTab", "tab"]) : setTabStatus(["tab", "selectedTab"]);
    }, [props.flashcards, props.decks]);

    useEffect(() => {
        filterFlashcards();
    }, [formFilter, viewMode, flashcardForm]);

    function filterFlashcards() {
        // console.log(props.flashcards)

        let filteredFlashcardsClone = [];

        for (let i = 0; i < props.flashcards.length; i++) {
            let flashcard = props.flashcards[i];
            let filtered = true;
            ["tense", "mood", "person", "number"].forEach(filter => {
                if (formFilter[filter] !== "All" && formFilter[filter] !== flashcard[filter]) filtered = false;
            });
            if (flashcard.tense === "" && flashcardForm === "conjugate") filtered = false;
            if (filtered) filteredFlashcardsClone.push(flashcard);
        }

        // console.log(flashcard.mood)
        // if ((flashcardForm === "conjugate") && (flashcard.tense === null) &&
        //     ((flashcard.mood !== "Indicative") &&
        //         (flashcard.mood !== "Subjunctive") &&
        //         (flashcard.mood !== "Conditional") &&
        //         (flashcard.mood !== "Imperative"))) { // RETHINK THIS and double check
        //     return;
        // }
        // if (flashcardForm === "conjugate" && flashcard.mood === "Gerund") return;

        // console.log(filteredFlashcards)
        selectDeselectAll("unselected");
        setFilteredFlashcards(filteredFlashcardsClone);
    }

    // Adds or removes a card to the deck
    function updateChosenCards(card) {
        let chosenCardsClone = props.chosenCards;
        let selectStatusClone = selectStatus;

        // If card is selected add the card to the deck and sort it
        if (selectStatusClone[card] === "unselected") {
            selectStatusClone[card] = "selected";
            chosenCardsClone.push(filteredFlashcards[card].id);
            chosenCardsClone.sort();
        } else { // When deselected remove the card from the deck
            selectStatusClone[card] = "unselected";
            chosenCardsClone.splice(chosenCardsClone.indexOf(filteredFlashcards[card].id), 1);
        }

        setSelectStatus([...selectStatusClone]);
        props.setChosenCards([...chosenCardsClone]);

        console.log(selectStatusClone, chosenCardsClone);
    }

    // Select or deselect all flashcards
    function selectDeselectAll(command) {
        let chosenCardsClone = [];
        let selectStatusClone = selectStatus;

        for (let i = 0; i < filteredFlashcards.length; i++) {
            selectStatusClone[i] = command;
            if (command === "selected") {
                chosenCardsClone.push(filteredFlashcards[i].id);
            }
        }

        setSelectStatus(selectStatusClone);
        props.setChosenCards(chosenCardsClone);

        // console.log(selectStatusClone, chosenCardsClone);
    }

    function navigateFlashcards(movement) {
        if ((flashcardNumber + movement) < filteredFlashcards.length && (flashcardNumber + movement) > -1)
            setFlashcardNumber(flashcardNumber + movement);
    }

    const onKeyPress = (event) => {
        // Don't allow in conjugate mode since it prevents moving within the input box (maybe there is a workaround when the input box isn't active?)
        if (flashcardForm === "conjugate") return;
        switch (event.key) {
            case "ArrowLeft":
                navigateFlashcards(-1);
                break;
            case "ArrowRight":
                navigateFlashcards(1);
                break;
        }
    };

    useKeyPress(["ArrowLeft", "ArrowRight"], onKeyPress);

    return (
        <div className="FlashcardGrid">
            {(props.parent === "Home" || props.parent === "Classroom") &&
                <div className="tabs">
                    <div
                        className={tabStatus[0]}
                        onClick={() => { setFlashcardForm("side1"); selectDeselectAll("unselected"); setTabStatus(["selectedTab", "tab"]); setViewMode("Presentation View"); }}
                    >
                        {titles[language].study}
                    </div>
                    <div
                        className={tabStatus[1]}
                        onClick={() => { setFlashcardForm("raw"); selectDeselectAll("unselected"); setTabStatus(["tab", "selectedTab"]); setViewMode("Grid View"); }}
                    >
                        {props.chosenUserID === -1 && titles[language].edit}
                        {props.chosenUserID > -1 && titles[language].copy}
                    </div>
                </div>
            }
            <div className="toolbar">
                <div className='toolbar-row' style={{ justifyContent: flashcardForm === "raw" && "space-between" }}>
                    {flashcardForm === "raw" ? // Edit mode
                        <>
                            <div className='choose-cards'>
                                <button
                                    title="Select All"
                                    className="select-all"
                                    disabled={filteredFlashcards.length === 0}
                                    onClick={() => selectDeselectAll("selected")}
                                >
                                    {titles[language].select_all}
                                </button>
                                <button
                                    title="Deselect All"
                                    className="deselect-all"
                                    disabled={filteredFlashcards.length === 0}
                                    onClick={() => selectDeselectAll("unselected")}
                                >
                                    {titles[language].deselect_all}
                                </button>
                            </div>
                            <div>
                                {(props.parent === "Home" && props.chosenUserID === -1) &&
                                    <Link to={{ pathname: `/search`, chosenDeck: props.chosenDeck }}>
                                        <button className="add-cards">{titles[language].add_cards}</button>
                                    </Link>
                                }
                            </div>
                        </>
                        : // Study Mode
                        <>
                            <div className="study-modes">
                                <div className='toolbar-title'>Flashcard Form</div>
                                <div className='toolbar-buttons'>
                                    <select
                                        value={flashcardForm}
                                        onChange={e => setFlashcardForm(e.target.value)}
                                    >
                                        <option value={"side1"}>{titles[language].flip_cards}</option>
                                        <option value={"conjugate"}>{titles[language].spell_conjuagation}</option>
                                    </select>
                                </div>
                            </div>
                            <div className='view-modes'>
                                <div className='toolbar-title'>View</div>
                                <div className='toolbar-buttons'>
                                    {(flashcardForm === "side1" || flashcardForm === "side2" || flashcardForm === "both_sides") &&
                                        <select
                                            value={flashcardForm}
                                            onChange={e => setFlashcardForm(e.target.value)}
                                        >
                                            <option value="side1">Side 1</option>
                                            <option value="side2">Side 2</option>
                                            {viewMode === "Presentation View" && <option value="both_sides">Both sides</option>}
                                        </select>
                                    }
                                    <select
                                        value={viewMode}
                                        onChange={e => {
                                            if (e.target.value === "Grid View" && flashcardForm === "both_sides") setFlashcardForm("side1");
                                            setViewMode(e.target.value)
                                        }}
                                    >
                                        <option value="Presentation View">Presentation View</option>
                                        <option value="Grid View">Grid View</option>
                                    </select>
                                </div>
                            </div>
                        </>
                    }
                </div>
                {props.parent !== "Search" && // Form filters
                    <>
                        <div className='filter-show-hide'>
                            <span onClick={() => setShowFormFilters(!showFormFilters)}>
                                Filter Forms <FontAwesomeIcon icon={showFormFilters ? faChevronUp : faChevronDown} />
                            </span>
                        </div>
                        {showFormFilters &&
                            <div className="toolbar-row filter-attributes">
                                {["tense", "mood", "person", "number"].map((attribute, a) => // Loop through every linguistic attribute in this order
                                    <div key={a}>
                                        <h3>{titles[language][attribute]}</h3>
                                        <select onChange={e => setFormFilter({ ...formFilter, [attribute]: e.target.value })}>
                                            {["All", ...linguisticInfo[attribute]].map((form, f) => // Loop through the labels for every linguistic attribute
                                                <option key={f} value={f === 0 ? "All" : form["tag_name"]}>
                                                    {f === 0 ? "All" : form["tag_it"]}
                                                </option>
                                            )}
                                        </select>
                                    </div>
                                )}
                            </div>
                        }
                    </>
                }
            </div>
            {filteredFlashcards.length > 0 &&
                <>
                    <div className={(viewMode === "Grid View" || tabStatus[1] === "selectedTab") ? "grid-view" : "prev-next"}>
                        {viewMode === "Grid View" &&
                            filteredFlashcards.map((flashcard, f) =>
                                <div className="FlashcardForms" key={f}>
                                    <div
                                        className={flashcardForm === "raw" ? selectStatus[f] : flashcardForm}
                                        onClick={() => flashcardForm === "raw" && updateChosenCards(f)}
                                    >
                                        <FlashcardForms
                                            verb={flashcard.verb}
                                            infinitive={flashcard.infinitive}
                                            tags={{
                                                tense: flashcard.tense,
                                                mood: flashcard.mood,
                                                person: flashcard.person,
                                                number: flashcard.number
                                            }}
                                            flashcardMode={flashcardForm}
                                            choice={""}
                                            parent={"FlashcardGrid"}
                                        />
                                    </div>
                                </div>
                            )}
                        {viewMode === "Presentation View" &&
                            <div className="FlashcardForms">
                                <div className={flashcardForm}>
                                    <FlashcardForms
                                        verb={filteredFlashcards[flashcardNumber].verb}
                                        infinitive={filteredFlashcards[flashcardNumber].infinitive}
                                        tags={{
                                            tense: filteredFlashcards[flashcardNumber].tense,
                                            mood: filteredFlashcards[flashcardNumber].mood,
                                            person: filteredFlashcards[flashcardNumber].person,
                                            number: filteredFlashcards[flashcardNumber].number
                                        }}
                                        flashcardMode={flashcardForm}
                                        choice={""}
                                        parent={"FlashcardGrid"}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                    {viewMode === "Presentation View" && tabStatus[0] === "selectedTab" && // previous next buttons
                        <div className='prev-next-buttons'>
                            <div className='prev-next-label-button' onClick={() => navigateFlashcards(-1)}>
                                <FontAwesomeIcon icon={faArrowCircleLeft} title="Previous" className="prev-next-button" />
                                <div className='prev-next-labels'>Prev</div>
                            </div>
                            <div className='flashcard-number'>
                                {flashcardNumber + 1}/{filteredFlashcards.length}
                            </div>
                            <div className='prev-next-label-button' onClick={() => navigateFlashcards(1)}>
                                <FontAwesomeIcon icon={faArrowCircleRight} title="Next" className="prev-next-button" />
                                <div className='prev-next-labels'>Next</div>
                            </div>
                        </div>
                    }
                </>
            }
        </div>
    );
}

export default FlashcardGrid;