import React from 'react';
import '../styles/ConfirmDelete.scss';
import titles from '../../data/titles.json';
import { useSession, useSessionUpdate } from '../SessionContext';

const ConfirmDelete = (props) => {

    const { language } = useSession();
    const { insertStringInPhrase } = useSessionUpdate();

    async function deleteType() {
        if (props.parent === 'Classroom') {
            props.manipulateClassroom('/api/deleteClassroom');
            props.setShowConfirmDelete(false);
            return;
        }

        const deleteFunc = props.objectToDelete.type === 'collection' ? '/api/deleteCollection' : '/api/deleteDeck';

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id: props.objectToDelete.id })
        };
        const response = await fetch(deleteFunc, requestOptions);
        if (!response.ok) return;

        // If the chosen deck is being deleted unchoose it
        if ((props.objectToDelete.type === 'deck') &&
            (props.objectToDelete.id === props.chosenDeck.id)) {
            props.setChosenDeck({ id: -1 });
        }

        props.setObjectToDelete({ name: "", id: -1, type: "" });
        props.getCollections();
    }

    console.log(props.objectToDelete);

    return (
        <div className="ConfirmDelete">
            <div className="window">
                <div className="areYouSure">
                    {insertStringInPhrase(titles[language].are_you_sure, [props.objectToDelete.name])}
                </div>
                <button onClick={() => deleteType()}>
                    {titles[language].yes}
                </button>
                <button onClick={() => {
                    if (props.parent === 'Classroom') {
                        props.setShowConfirmDelete(false);
                        return;
                    }
                    props.setObjectToDelete({ name: "", id: -1, type: "" });
                }}>
                    {titles[language].no}
                </button>
            </div>
        </div>
    );
}

export default ConfirmDelete;