import React from 'react';

const Feedback = () => {
    return (
        <div className="Feedback">
            <iframe
                title="Feedback"
                src="https://docs.google.com/forms/d/e/1FAIpQLSeshcZYq1GhI4v8o1Od7tusV2DLEdXDPyFNV-i3Yspseu7u_Q/viewform?embedded=true"
                height="1000"
                width="100%"
                style={{ overflow: "visible", border: "none", pointerEvents: "auto" }}
            >
                Loading…
            </iframe>
        </div>
    );
}

export default Feedback;