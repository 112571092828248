import React, { useEffect, useState } from 'react';
import './styles/Results.scss';
import FlashcardForms from './util/FlashcardForms';
// import './styles/FlashcardForms.scss';
import { Link } from 'react-router-dom';
import titles from '../data/titles.json';
import { useSession } from './SessionContext';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Results = (props) => {
	let parentProps;

	if (props.location) parentProps = props.location;
	else parentProps = props;

	const { quizType, points, correct, chosenDeck, class_id, parent } = parentProps;
	const [history, setHistory] = useState(props.previousAttempt ? [] : parentProps.history);

	const { language } = useSession();

	let deckSize = props.previousAttempt ? props.deck_size : history?.length;

	useEffect(() => {
		if (parent === "Classroom") addRecord();
		if (props.previousAttempt) getRecord();
	}, []);

	async function addRecord() {
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				points: points,
				score: correct,
				deck_size: history.length,
				time: 1,
				deck_id: chosenDeck.id,
				class_id: class_id,
				quiz_type: quizType,
				attempts: parentProps.history
			}),
		}

		const response = await fetch("/api/addRecord", requestOptions)
		if (!response.ok) return
	}

	async function getRecord() {
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ class_history_id: props.class_history_id })
		}

		const response = await fetch("/api/getRecord", requestOptions)
		if (!response.ok) return
		const data = await response.json();

		setHistory(data.history)
		console.log(data.history)
	}

	return (
		<div className="Results">
			<h2 className='header'>{titles[language].results}</h2>
			<h3>
				<span>{correct}/{deckSize} ~ {points}pts</span>
			</h3>
			{props.previousAttempt &&
				<div className='member-attempt-details'>
					<div className='stat'>{props.username}</div> -
					<div className='stat'>{props.deckName}</div> -
					<div className='stat'>{titles[language].attempt} {props.attemptNumber}</div>
				</div>
			}

			<ul>
				{history?.map((flashcard, f) =>
					<li key={f}>
						<div className="FlashcardForms">
							<div className={flashcard.quiz_type}>
								<FlashcardForms
									verb={flashcard.verb}
									infinitive={flashcard.infinitive}
									tags={{
										tense: flashcard.tense,
										mood: flashcard.mood,
										person: flashcard.person,
										number: flashcard.number
									}}
									flashcardMode={flashcard.quiz_type}
									choice={""}
									parent="Results"
									answered={true}
									questionNumber={f}
									history={flashcard}
								/>
							</div>
						</div>
					</li>
				)}
			</ul>
			{props.previousAttempt ?
				<button onClick={() => { props.setAttemptToView({}); }}>
					Back
				</button>
				:
				<>
					{parent === "Classroom" ?
						<Link to={{ pathname: "/classroom", chosenClassroom: props.location.chosenClassroom }}>
							<button>Back to classroom</button>
						</Link>
						:
						<Link to="/home">
							<button>Home</button>
						</Link>
					}
				</>
			}


		</div>
	);
}

export default Results;