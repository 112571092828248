import React, { useState } from 'react';
import DeckSelection from './DeckSelection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import './styles/Search.scss';
import titles from '../data/titles.json';
import { useSession } from './SessionContext';

function Analyze(props) {
    const [verb, setVerb] = useState('');
    const [flashcards, setFlashcards] = useState([]);

    const { language } = useSession();

    // Analyzes the verb, tense, person
    function analyzeVerb() {
        if (verb !== "") {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ verb: verb })
            };
            fetch('/api/getVerbAnalysis', requestOptions)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    let flashcardsClone = [];

                    for (var i = 0; i < data.analysis.length; i++) {
                        flashcardsClone.push({
                            tense: data.analysis[i].tense_tag,
                            mood: data.analysis[i].mood_tag,
                            person: data.analysis[i].person_tag,
                            number: data.analysis[i].number_tag,
                            infinitive: data.analysis[i].root_tag,
                            verb: data.analysis[i].verb,
                            id: data.analysis[i].id
                        });
                    }
                    // Update the current analysis
                    setFlashcards(flashcardsClone);
                    //console.log(data)
                })
                .catch(error => console.error("Fetch operation failed.", error));
        } else {
            alert("Fill in the verb");
        }
    }

    return (
        <div className="Search">
            <div className="container">
                <div className="instructions">
                    {titles[language].verb_analyzer_instructions}
                </div>
                <div className="inputVerb">
                    <input
                        type="text"
                        size="lg"
                        placeholder="verb"
                        value={verb}
                        onChange={e => setVerb(e.target.value)}
                        onKeyPress={event => (event.keyCode || event.which === 13) && analyzeVerb()}
                    />
                    <FontAwesomeIcon
                        icon={faSearch}
                        className="searchButton"
                        title="Search"
                        onClick={() => analyzeVerb()}
                    />
                </div>
            </div>
            <div>
                {flashcards.length > 0 &&
                    <DeckSelection
                        chosenUserID={-1}
                        flashcards={flashcards}
                        parent="VerbAnalyzer"
                    />
                }
            </div>
        </div>
    );
}

export default Analyze;