import React, { useState } from 'react';
import DeckSelection from './DeckSelection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import './styles/Search.scss';
import titles from '../data/titles.json';
import linguisticInfo from '../data/linguisticInfo.json';
import { useSession } from './SessionContext';

function VerbSearch(props) {
    const { language } = useSession();

    const [verb, setVerb] = useState('');
    const [flashcards, setFlashcards] = useState([]);
    const [selectedForms, setSelectedForms] = useState({
        tense: Array(linguisticInfo.tense.length).fill("unchecked"),
        mood: Array(linguisticInfo.mood.length).fill("unchecked"),
        person: Array(linguisticInfo.person.length).fill("unchecked"),
        number: Array(linguisticInfo.number.length).fill("unchecked")
    });

    // React.useEffect(() => { // use this to view irregular verbs, list can be found in data directory
    //     (async () => {
    //         const response = await fetch("/api/getIrregularVerbs")
    //         const data = await response.json();
    //         // print all the root_verbs
    //         for (let i = 0; i < data.analysis.length; i++) {
    //             console.log(data.analysis[i].root_tag);
    //         }
    //     })();
    // }, []);

    function searchVerbWithFilters() {
        let tags = { tense: [], mood: [], person: [], number: [] }; // Store what the user checked and inputted

        // Loop through tense, mood, person, number
        ["tense", "mood", "person", "number"].forEach((attribute) => {
            // Loop through attributes of the lingiustic attributes
            linguisticInfo[attribute].forEach((form, f) => {
                // If the checkbox is checked append tag info
                if (selectedForms[attribute][f] === "checked") tags[attribute].push(form["tag_name"]);
            });
        });

        console.log(tags)

        const requestOptions = { // Fetch flashcard data
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ verb: verb, tags: tags })
        };
        fetch('/api/verbSearch', requestOptions)
            .then(response => response.json())
            .then(data => {
                let flashcardsClone = []; // Empty flashcards

                for (let i = 0; i < data.analysis.length; i++) { // Loop through all the generated verbs
                    flashcardsClone.push({ // Store attributes of current verb form
                        tense: data.analysis[i].tense_tag,
                        mood: data.analysis[i].mood_tag,
                        person: data.analysis[i].person_tag,
                        number: data.analysis[i].number_tag,
                        infinitive: data.analysis[i].root_tag,
                        verb: data.analysis[i].verb,
                        id: data.analysis[i].id
                    });
                }

                setFlashcards(flashcardsClone); // Update what flashcards to display

                console.log(flashcards);
            });
    }

    // Changes the state of checked and unchecked boxes
    function handleCheckChange(attributes, a) {
        let selectedFormsClone = { ...selectedForms } // Clone selectedForms for manipulation

        if (selectedFormsClone[attributes][a] === "unchecked") selectedFormsClone[attributes][a] = "checked";
        else selectedFormsClone[attributes][a] = "unchecked";

        setSelectedForms(selectedFormsClone)
        // console.log(selectedForms)
    }

    return (
        <div className="Search">
            <div className="container">
                <div className="attributeLists">
                    {["tense", "mood", "person", "number"].map((attribute, a) => // Loop through every linguistic attribute in this order
                        <ul key={a}>
                            <li><h1>{titles[language][attribute]}</h1></li>
                            {linguisticInfo[attribute].map((form, f) => // Loop through the labels for every linguistic attribute
                                <li key={f}>
                                    <div
                                        value={selectedForms[attribute][f]}
                                        className={selectedForms[attribute][f]}
                                        onClick={() => handleCheckChange(attribute, f)}
                                    >
                                        {form["tag_it"]}
                                    </div>
                                </li>
                            )}
                        </ul>
                    )}
                </div>
                <div className="inputVerb">
                    <textarea
                        placeholder="verb"
                        name="verb"
                        value={verb}
                        onChange={e => setVerb(e.target.value)}
                        onKeyPress={event => {
                            if (event.keyCode || event.which === 13) {
                                event.preventDefault();
                                searchVerbWithFilters()
                            }
                        }}
                    />
                    <FontAwesomeIcon
                        icon={faSearch}
                        className="searchButton"
                        title="Search"
                        onClick={() => searchVerbWithFilters()}
                    />
                </div>
                <div className='searchBarInstruction'>
                    Enter an infinitive verb. You can search multiple verbs at once by separating them with a comma <b>,</b> or semicolon <b>;</b>
                </div>
            </div>
            <DeckSelection
                chosenUserID={-1}
                flashcards={flashcards}
                chosenDeckLink={props.location.chosenDeck}
                parent="Search"
            />
        </div>
    )
}

export default VerbSearch;