import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
import ConfirmDelete from './util/ConfirmDelete';
import ChooseQuiz from './ChooseQuiz';
import ClassroomGrid from './ClassroomGrid';
import FlashcardGrid from './FlashcardGrid';
import MiniDeckSelection from './MiniDeckSelection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faPlusCircle, faPen, faArrowLeft, faEye, faEyeSlash, faCheck, faArrowsAltV, faUserGraduate, faCopy/*, faTimesCircle */ } from '@fortawesome/free-solid-svg-icons';
import SmallDeck from '../images/small.png';
import MediumDeck from '../images/medium.png';
import LargeDeck from '../images/large.png';
import titles from '../data/titles.json';
import { useSession } from './SessionContext';
import './styles/Home.scss';
import './styles/DeckSelection.scss';

const DeckSelection = (props) => {
    const [collections, setCollections] = useState({ user: [], other: [] });
    const [decks, setDecks] = useState({ user: [], other: [] });
    const [chosenDeck, setChosenDeck] = useState({ id: -1 });

    const [flashcards, setFlashcards] = useState([]);
    const [chosenCards, setChosenCards] = useState([]);

    const [collectionEditMode, setCollectionEditMode] = useState({ nameEdit: [], pluses: [] });
    const [deckEditMode, setDeckEditMode] = useState([]);
    const [isPublic, setIsPublic] = useState(true);

    const [deckName, setDeckName] = useState("");
    const [renamedDeck, setRenamedDeck] = useState("");
    const [collectionName, setCollectionName] = useState("");
    const [renamedCollection, setRenamedCollection] = useState("");

    const [editEnabled, setEditEnabled] = useState(false);
    const [objectToDelete, setObjectToDelete] = useState({ name: "", id: -1, type: "" });
    const [collectionForClass, setCollectionForClass] = useState({ id: -1 });

    const [manageFlashcards, setManageFlashcards] = useState(false);

    const { language, accountType } = useSession();

    // instead of check for -1 in chosenDeck.id create a more readable solution <- at this point not gonna happen

    useEffect(() => { props.chosenDeckLink !== undefined && setChosenDeck(props.chosenDeckLink); }, [props.chosenDeckLink]);

    useEffect(() => { getCollections(); }, [props.chosenUserID]);

    useEffect(() => { initializeUnclick(); }, [collections, decks]);

    async function getCollections() {
        const userID = props.chosenUserID === undefined ? -1 : props.chosenUserID;

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                user_id: userID,
                class_id: props.chosenClassroomID === undefined ? -1 : props.chosenClassroomID
            })
        };

        const response = await fetch('/api/getCollections', requestOptions);
        if (!response.ok) return;
        const data = await response.json();

        userID === -1 ? setEditEnabled(true) : setEditEnabled(false);

        props.parent === "Versus" && setEditEnabled(false);

        setCollections(data.collections);
        setDecks(data.decks);

        if (props.parent === "Classroom" && data.collections.other.length > 0) props.recieveCollections(data.collections.other, data.decks.other); // Pass data to parent
    }

    async function initializeUnclick() { // Used usually after something has been edited so user length is used
        setCollectionEditMode({
            nameEdit: Array(collections.user.length).fill(false),
            pluses: Array(collections.user.length).fill(false)
        });
        setDeckEditMode(Array(decks.user.length).fill(false));
        setDeckName("");
        setRenamedCollection("");
        setCollectionName("")
        setRenamedDeck("");
    }

    // Rename a deck or collection while closing any previous inputs for renaming
    async function enableNameEdit(i, oldName, type) {
        await initializeUnclick();

        console.log(deckEditMode);

        let editMode;

        // Choose whether a collection or deck is going to be edited
        if (type === "collection") {
            editMode = { ...collectionEditMode };
            editMode.nameEdit[i] = true;
            setCollectionEditMode(editMode);
            setRenamedCollection(oldName);
        } else {
            editMode = [...deckEditMode];
            editMode[i] = true;
            setDeckEditMode(editMode);
            setRenamedDeck(oldName);
        }
    }

    function changePublicity(collectionID) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ collectionID: collectionID })
        };
        fetch('/api/changePublicity', requestOptions).then(response => response.json()).then(() => getCollections());
    }

    // Generalizes adding collections and decks
    async function add(collectionID = null, name, type) {
        const addFunc = type === 'collection' ? '/api/addCollection' : '/api/addDeck';

        if (name.length > 0) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    collectionID: collectionID,
                    name: name,
                    isPublic: isPublic ? 1 : 0,
                })
            };
            const response = await fetch(addFunc, requestOptions);
            if (!response.ok) return;
            const data = await response.json();

            if (data['error'] !== undefined) {
                alert(data['error']);
                return;
            }

            getCollections();
            if (collectionID !== null && type === 'collection') alert(`You have a copy of ${name}.`); // temp
            return;
        }
        alert(`Please name your ${type}.`);
    }

    // Generalizes renaming collections and decks
    async function rename(id, type) {
        const renamedType = type === 'collection' ? renamedCollection : renamedDeck;

        if (renamedType.length > 0) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ id: id, name: renamedType, type: type })
            };
            const response = await fetch('/api/rename', requestOptions);
            if (!response.ok) return;
            getCollections();
            type === 'deck' && setChosenDeck({ ...chosenDeck, name: renamedType }); // helps refresh the deck name in ChooseQuiz a bit hacky, ideally would call getFlashcards() instead?
            return;
        }
        alert(`Please name your ${type}.`);
    }

    async function getFlashcards(deck) { // Helps enable deck details to be shown
        console.log(deck);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ deck_id: deck.id })
        };
        const response = await fetch('/api/getUserFlashcards', requestOptions);
        if (!response.ok) return;
        const data = await response.json();

        let newFlashcards = []; // Clone flashcards for easy manipulation

        // Store attributes of every verb
        for (let i = 0; i < data.flashcards.length; i++) {
            newFlashcards.push({
                tense: data.flashcards[i].tense_tag,
                mood: data.flashcards[i].mood_tag,
                person: data.flashcards[i].person_tag,
                number: data.flashcards[i].number_tag,
                infinitive: data.flashcards[i].root_tag,
                verb: data.flashcards[i].verb,
                id: data.flashcards[i].id
            });
        }

        if (props.parent === "Versus") {
            deck.flashcards = newFlashcards;
            props.updateMultiplayerFlashcards(deck); // Remove cards and deck if already chosen
        } else {
            setFlashcards([...newFlashcards]);
            setChosenDeck(deck);
        }
    }

    function highlightDeck(deckID) { // Highlights chosen deck(s)
        let highlight = { backgroundColor: "#e3dafd", border: "3px solid hsl(275, 100%, 25%)" }

        if (props.parent === "Versus") {
            if (props.chosenDecks.some(cd => cd.id === deckID)) return highlight;
        }
        if (chosenDeck.id === deckID) return highlight;
    }

    let userOrOther = props.chosenUserID === -1 ? "user" : "other";
    let decksInCollection = [];
    let trueStartIndex = 0;

    let DSclassName = editEnabled ? "yourCollections " : "otherCollections ";
    if (props.parent === "Versus") DSclassName += "versus";

    return (
        <>
            {(props.parent === "Home" || props.parent === "Classroom" || props.parent === "Versus") &&
                <div className={DSclassName}>
                    {props.parent === "Versus" &&
                        <div className='head'>
                            <h2 className='header-b'>Deck Selection</h2>
                            <button onClick={() => props.setOpenDeckSelection(false)}>Done</button>
                        </div>
                    }
                    {collections[userOrOther].map((collection, c) => {
                        trueStartIndex += decksInCollection.length;
                        decksInCollection = decks[userOrOther].filter(deck => deck.collection_id === collection.id);

                        return <div key={c} className="collection"
                            // If a deck is chosen and we aren't the Versus page just show the collection of that deck
                            style={chosenDeck.id > -1 && chosenDeck.collection_id !== collection.id && props.parent !== "Versus" ? { display: 'none' } : {}}
                        >
                            <div
                                className={collectionEditMode.nameEdit[c] ? "collectionNameEdit" : "collectionName"}
                            //style={props.parent === "Versus" ? { WebkitUserSelect: "none" } : {}}
                            >
                                <h2>{collection.name}</h2>
                                {editEnabled ?
                                    <>
                                        <div className="options">
                                            <FontAwesomeIcon
                                                icon={faPen}
                                                title="Rename"
                                                className="edit"
                                                onClick={() => enableNameEdit(c, collection.name, "collection")}
                                            />
                                            <FontAwesomeIcon
                                                icon={collection.public === 1 ? faEye : faEyeSlash}
                                                title={collection.public === 1 ? "Public" : "Private"}
                                                className="lock"
                                                onClick={() => changePublicity(collection.id)}
                                            />
                                            {accountType === 'teacher' &&
                                                <FontAwesomeIcon
                                                    icon={faUserGraduate}
                                                    title="Choose class"
                                                    className="delete"
                                                    onClick={() => setCollectionForClass(collection)}
                                                />
                                            }
                                            <FontAwesomeIcon
                                                icon={faTrashAlt}
                                                title="Delete Collection"
                                                className="delete"
                                                onClick={() => setObjectToDelete({ name: collection.name, id: collection.id, type: 'collection' })}
                                            />
                                        </div>
                                        <div className="editMode">
                                            <FontAwesomeIcon
                                                icon={faArrowLeft}
                                                title="Back"
                                                className="backButton"
                                                onClick={() => initializeUnclick()}
                                            />
                                            <input
                                                className="collectionNameInput"
                                                placeholder="Collection Name"
                                                value={renamedCollection}
                                                name="renamedCollection"
                                                onChange={e => setRenamedCollection(e.target.value)}
                                                onKeyPress={e => (e.keyCode || e.which === 13) && rename(collection.id, 'collection')}
                                            />
                                            <FontAwesomeIcon
                                                icon={faCheck}
                                                title="Rename Collection"
                                                className="checkButton"
                                                style={renamedCollection.length > 0 ? {} : { display: 'none' }}
                                                onClick={() => rename(collection.id, 'collection')}
                                            />
                                        </div>
                                    </>
                                    :
                                    <div className="options">
                                        <FontAwesomeIcon
                                            icon={faCopy}
                                            title="Copy Collection"
                                            onClick={() => add(collection.id, collection.name, 'collection')} // Copy collection
                                        />
                                    </div>
                                }
                            </div>
                            <div className="decks">
                                {decksInCollection.map((deck, d) => {
                                    let trueIndex = d + trueStartIndex; // Deck index count won't restart to 0 for every collection this way

                                    return <div
                                        key={d}
                                        className="deck" // highlight the deck(s) chosen
                                        style={highlightDeck(deck.id)}
                                        onClick={() => getFlashcards(deck)}
                                    >
                                        <div className="deckIcon" >
                                            {deck.size === 0 && // Show certain image based on the deck size
                                                <div
                                                    className="none"
                                                    style={{
                                                        boxShadow: chosenDeck.id === deck.id && "inset 0 0 30px #e3dafd",
                                                        background: chosenDeck.id === deck.id && "#a78aff"
                                                    }}
                                                >
                                                </div>
                                            }
                                            {(deck.size > 0 && deck.size <= 15) && <img src={SmallDeck} alt="Small Deck" />}
                                            {(deck.size > 15 && deck.size <= 30) && <img src={MediumDeck} alt="Medium Deck" />}
                                            {deck.size > 30 && <img src={LargeDeck} alt="Large Deck" />}
                                        </div>
                                        {editEnabled &&
                                            <div className="options">
                                                <FontAwesomeIcon
                                                    icon={faTrashAlt}
                                                    title="Delete Deck"
                                                    onClick={() => setObjectToDelete({ name: deck.name, id: deck.id, type: 'deck' })}
                                                />
                                                <br />
                                                <FontAwesomeIcon
                                                    icon={faPen}
                                                    title="Rename"
                                                    onClick={() => enableNameEdit(trueIndex, deck.name, "deck")}
                                                />
                                            </div>
                                        }
                                        <div className={deckEditMode[trueIndex] ? "deckNameEdit" : "deckName"}>
                                            {deckEditMode[trueIndex] ?
                                                <div>
                                                    <FontAwesomeIcon
                                                        icon={faArrowLeft}
                                                        title="Back"
                                                        className="backButton"
                                                        onClick={() => initializeUnclick()}
                                                    />
                                                    <input
                                                        className="deckNameInput"
                                                        placeholder="Deck Name"
                                                        value={renamedDeck}
                                                        name="renamedDeck"
                                                        onChange={e => setRenamedDeck(e.target.value)}
                                                        onKeyPress={e => (e.keyCode || e.which === 13) && rename(deck.id, 'deck') /*check button*/}
                                                    />
                                                </div>
                                                :
                                                <div>{deck.name} ({deck.size})</div>
                                            }
                                        </div>
                                    </div>
                                })}
                                {editEnabled && // Add a new deck 
                                    <div className={collectionEditMode.pluses[c] ? "clicked" : "unclicked"}>
                                        <FontAwesomeIcon
                                            icon={faArrowLeft}
                                            title="Back"
                                            className="backButton"
                                            onClick={() => initializeUnclick()}
                                        />
                                        <FontAwesomeIcon
                                            icon={faPlusCircle}
                                            title="Add Deck"
                                            className="addButton"
                                            onClick={() => {
                                                if (collectionEditMode.pluses[c]) { // Deck adder is on
                                                    add(collection.id, deckName, 'deck');
                                                } else { // Activate deck adder
                                                    initializeUnclick();
                                                    let pluses = [...collectionEditMode.pluses];
                                                    pluses[c] = true;
                                                    setCollectionEditMode({
                                                        nameEdit: [...collectionEditMode.nameEdit],
                                                        pluses: pluses
                                                    });
                                                }
                                            }}
                                        />
                                        <input
                                            placeholder={titles[language].new_deck}
                                            className="deckNameInput"
                                            name="deckName"
                                            value={deckName}
                                            onChange={e => setDeckName(e.target.value)}
                                            onKeyPress={e => (e.keyCode || e.which === 13) && add(collection.id, deckName, 'deck')}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    })}
                    {editEnabled && chosenDeck.id === -1 && // Add a new collection
                        <div className="addCollection">
                            <input
                                className="collectionNameInput"
                                placeholder={titles[language].new_collection}
                                name="collectionName"
                                value={collectionName}
                                onChange={e => setCollectionName(e.target.value)}
                                onKeyPress={e => (e.keyCode || e.which === 13) && add(null, collectionName, 'collection')}
                            />
                            <FontAwesomeIcon
                                icon={isPublic ? faEye : faEyeSlash}
                                title={isPublic ? "Public" : "Private"}
                                className="lock"
                                onClick={() => setIsPublic(!isPublic)}
                            />
                            <FontAwesomeIcon
                                icon={faPlusCircle}
                                title="Add Collection"
                                className="addButton"
                                style={collectionName.length > 0 ? {} : { display: 'none' }}
                                onClick={() => add(null, collectionName, 'collection')}
                            />
                        </div>
                    }
                    <div
                        className="expand"
                        style={(chosenDeck.id > -1 && props.parent !== "Versus") ? {} : { display: 'none' }}
                    >
                        <FontAwesomeIcon
                            icon={faArrowsAltV}
                            title="Expand"
                            className="addButton"
                            onClick={() => {
                                setChosenDeck({ id: -1 });
                                setChosenCards([]);
                            }}
                        />
                    </div>
                    {collections.other.length === 0 && props.parent === "Classroom" &&
                        <>{accountType === "teacher" ? <>{titles[language].classroom_instructions}</> : <>{titles[language].classroom_instructions_student}</>} </>
                    }
                </div>
            }
            {(props.parent === "Home" || props.parent === "Classroom") && // Children components below
                <ChooseQuiz
                    chosenClassroom={props.chosenClassroom}
                    chosenDeck={chosenDeck}
                    flashcards={flashcards}
                    parent={props.parent}
                    class_id={props.chosenClassroomID === undefined ? -1 : props.chosenClassroomID}
                />
            }
            {(((props.parent === "Home" || props.parent === "Classroom" || props.parent === "Versus") && chosenDeck.id > -1) || (props.parent !== "Home" && props.parent !== "Classroom" && props.parent !== "Versus")) &&
                <FlashcardGrid
                    decks={decks}
                    flashcards={(props.parent === "Home" || props.parent === "Classroom" || props.parent === "Versus") ? flashcards : props.flashcards}
                    parent={props.parent}
                    chosenDeck={chosenDeck}
                    chosenUserID={props.chosenUserID}
                    chosenCards={chosenCards}
                    setChosenCards={setChosenCards}
                    setManageFlashcards={setManageFlashcards}
                />
            }
            {((chosenDeck.id > -1 && flashcards.length > 0 && manageFlashcards) || props.parent === "Search" || props.parent === "VerbAnalyzer") &&
                <MiniDeckSelection
                    getCollections={getCollections}
                    getFlashcards={getFlashcards}
                    collections={collections}
                    decks={decks}
                    chosenDeck={chosenDeck}
                    setChosenDeck={setChosenDeck}
                    chosenCards={chosenCards}
                    setChosenCards={setChosenCards}
                    deckName={deckName}
                    setDeckName={setDeckName}
                    add={add}
                    chosenUserID={props.chosenUserID}
                    parent={props.parent}
                />
            }
            {objectToDelete.id !== -1 && // If a deck or collection is chosen to be deleted
                <ConfirmDelete
                    objectToDelete={objectToDelete}
                    setObjectToDelete={setObjectToDelete}
                    getCollections={getCollections}
                    chosenDeck={chosenDeck}
                    setChosenDeck={setChosenDeck}
                />
            }
            {collectionForClass.id > -1 && // If a collection is going to be added to a classroom
                <ClassroomGrid
                    collectionForClass={collectionForClass}
                    setCollectionForClass={setCollectionForClass}
                    parent={"DeckSelection"}
                />
            }
        </>
    );
}

export default DeckSelection;